import React from "react";
import classes from "./additional-service.module.css";
import { Link } from "react-router-dom";

const AdditionalService = () => {
  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <p>We also provide</p>
        <span className={classes.underlined}>additional services</span>
      </div>
      <div className={classes.cardContainer}>
        <div className={`${classes.card} ${classes.first}`}>
          <Link
            style={{ color: "black", textDecoration: "none" }}
            to={"/services/additional-services#3"}
          >
            <div className={classes.cardImg}>
              <p className={classes.headingSm}>Offshore Solutions</p>
            </div>
          </Link>
          <Link
            style={{ color: "black", textDecoration: "none" }}
            to={"/services/additional-services#3"}
          >
            <p className={classes.headingLg}>Offshore Solutions</p>
          </Link>
        </div>

        <div className={`${classes.card} ${classes.second}`}>
          <Link
            style={{ color: "black", textDecoration: "none" }}
            to={"/services/additional-services#1"}
          >
            <div className={classes.cardImg}>
              <p className={classes.headingSm}>
                Regulated Management Company Formation
              </p>
            </div>
          </Link>
          <Link
            style={{ color: "black", textDecoration: "none" }}
            to={"/services/additional-services#1"}
          >
            <p className={classes.headingLg}>
              Regulated Management Company Formation
            </p>
          </Link>
        </div>

        <div className={`${classes.card} ${classes.third}`}>
          <Link
            style={{ color: "black", textDecoration: "none" }}
            to={"/services/additional-services#4"}
          >
            <div className={classes.cardImg}>
              <p className={classes.headingSm}>Virtual Office Set Up</p>
            </div>
          </Link>
          <Link
            style={{ color: "black", textDecoration: "none" }}
            to={"/services/additional-services#4"}
          >
            <p className={classes.headingLg}>Virtual Office Set Up</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdditionalService;
