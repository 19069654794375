import React, { useEffect } from "react";
import BridgeGap from "../components/Home/BridgeGap";
import FAQ from "../components/Home/FAQ";
import Hero from "../components/Home/Hero";
import Works from "../components/Home/Works";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Help from "../components/Home/Help";
import Advantages from "../components/Service/Advantages";
import AdditionalService from "../components/Home/AdditionalService";
import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    document.title = "Maximizing Market Efficiency | Prime Bridge";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Discover Prime Bridge's innovative liquidity solutions designed for hedge funds, high net worth individuals, and family firms. Request a demo today!"
        />
      </Helmet>
      <Hero />
      <Help />
      <AdditionalService />
      {/* <Works /> */}
      <Advantages />
    </WebsiteLayout>
  );
};

export default Home;
