import React, { useEffect, useRef, useState } from "react";
import ImportanceCard from "./ImportanceCard";
import classes from "./importance.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const importances = [
  {
    title: "Market Making and Liquidity Solutions",
    message:
      "Prime Bridge is a leading provider of market making and liquidity solutions, helping clients navigate the financial markets and achieve their investment goals. Our innovative solutions and deep expertise ensure that they always have access to the liquidity and market intelligence needed to make informed investment decisions.",
    fade: "left",
  },
  {
    title: "Advanced Analytics and Technology",
    message:
      "Prime Bridge leverages cutting-edge technology and powerful predictive analytics to deliver the insights and solutions our clients need to stay ahead of the market. Our innovative systems and expertise ensure that our clients have the tools and information they need to succeed in a rapidly changing financial landscape.",
    fade: "right",
  },
  {
    title: "Client-Focused Approach",
    message:
      "At Prime Bridge, our top priority is always our clients. We work closely with each of our clients to understand their specific needs and goals, and we strive to deliver customized solutions that help them achieve their objectives. Our commitment to client service and satisfaction is what sets us apart in the financial industry.",
    fade: "left",
  },
];

const Importance = () => {
  const [sliderIndex, setSliderIndex] = useState("0");
  const sliderRef = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current?.slickGoTo(parseInt(sliderIndex));
    }
  }, [sliderIndex]);
  return (
    <div className={classes.container}>
      <p className={classes.heading}>Our Importance</p>

      <div className={classes.sliderContainer}>
        <Slider
          afterChange={(index) => setSliderIndex(index)}
          ref={sliderRef}
          {...settings}
        >
          {importances?.map((importance, index) => (
            <ImportanceCard
              key={index}
              message={importance.message}
              heading={importance.title}
            />
          ))}
        </Slider>
        <div className={classes.sliderInputContainer}>
          <input
            type="range"
            min="0"
            max="2"
            value={sliderIndex}
            onChange={(e) => setSliderIndex(e.target.value)}
            className={classes.slider}
            id="myRange"
          />
        </div>
      </div>
      <div className={classes.importanceList}>
        {importances?.map((importance, index) => (
          <ImportanceCard
            key={index}
            message={importance.message}
            heading={importance.title}
            fade={`fade-${importance.fade}`}
          />
        ))}
      </div>

      <Link to={"/services"} style={{ textDecoration: "none" }}>
        <button className={classes.serviceBtn}>Explore Services</button>
      </Link>
    </div>
  );
};

export default Importance;
