import React, { useEffect } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Hero from "../components/Service/Hero";
import Works from "../components/Home/Works";
import Stats from "../components/Service/Stats";
// import Advantages from "../components/Service/Advantages";
import Services from "../components/Service/Services";
import { Helmet } from "react-helmet";

const Service = () => {
  useEffect(() => {
    document.title = "Our Services | Prime Bridge";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Explore Prime Bridge's complete list of innovative liquidity solutions and additional services to support your business. Choose the best solution for you."
        />
      </Helmet>
      <Hero />
      <Services />

      <Works />
      <Stats />
    </WebsiteLayout>
  );
};

export default Service;
