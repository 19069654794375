import React, { useEffect } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Hero from "../components/Learn/Hero";
import Articles from "../components/Learn/Articles";
import { Helmet } from "react-helmet";

const Learn = () => {
  useEffect(() => {
    document.title =
      "Learn More About Financial Markets | Prime Bridge Articles";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Explore Prime Bridge's informative articles about financial markets. Gain knowledge about the market and how it can benefit your business."
        />
      </Helmet>
      <Hero />
      <Articles />
    </WebsiteLayout>
  );
};

export default Learn;
