import React from "react";
import classes from "./pagebody.module.css";

const PageBody = () => {
  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <p className={classes.heading}>
          <span className={classes.lg}>Prime Bridge Privacy Policy</span>
          <span className={classes.sm}>Privacy Policy</span>
        </p>
      </div>

      <div className={classes.contentContainer}>
        <p className={classes.text}>
          At Prime Bridge, we take the privacy of our client's personal data
          seriously and are committed to protecting it. This Privacy Policy
          outlines our practices concerning collecting, using, sharing, and
          protecting personal data.
        </p>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>1.</span> Information Collection
          </p>
          <p className={classes.text}>
            Prime Bridge collects information from its clients and website
            visitors to provide them with the best possible services and
            experience. This information may include personal data such as
            names, addresses, contact information, and financial information.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>2.</span> Information Use
          </p>
          <p className={classes.text}>
            Prime Bridge uses collected information to provide its clients with
            the requested services and improve its platform's overall
            functionality. This may include using personal data for account
            management, trade execution, and risk management.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>3.</span> Information Sharing
          </p>
          <p className={classes.text}>
            Prime Bridge does not sell, rent, or otherwise share its clients'
            data with third parties except as required by law. Prime Bridge may
            share information with its affiliates and service providers to
            provide its services.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>4.</span> Information Security
          </p>
          <p className={classes.text}>
            Prime Bridge takes the security of its client's data seriously and
            implements appropriate technical and organizational measures to
            protect it. This includes secure storage and transmission of
            information and regular security audits and risk assessments.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>5.</span> Data Retention
          </p>

          <p className={classes.text}>
            Prime Bridge retains personal data for as long as necessary to
            provide its services and meet legal requirements. After this time,
            personal data will be securely deleted.
          </p>
        </div>
        <div className={classes.card}>
          <p className={classes.cardHeading}>
            {" "}
            <span className={classes.smallText}>6.</span> Data Access
          </p>

          <p className={classes.text}>
            Prime Bridge's clients have the right to access their personal data
            and request that it be corrected or deleted. Clients may exercise
            this right by contacting Prime Bridge's support team.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            {" "}
            <span className={classes.smallText}>7.</span> Changes to Privacy
            Policy
          </p>
          <p className={classes.text}>
            Prime Bridge may update this privacy policy occasionally. Clients
            should regularly review this policy to stay informed of any changes.
          </p>
        </div>

        <p className={`${classes.text} ${classes.last}`}>
          By using Prime Bridge's services, clients acknowledge and agree to the
          terms of this privacy policy. If you have questions or concerns about
          Prime Bridge's privacy policy, don't hesitate to contact our support
          team
        </p>
      </div>
    </div>
  );
};

export default PageBody;
