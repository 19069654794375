import React from "react";
import classes from "./additional-item.module.css";

const AdditionalServiceItem = ({
  title,
  description,
  icon,
  isoffShore,
  id,
}) => {
  return (
    <div id={id} className={classes.mainContainer}>
      <div className={classes.mainIconContainer}>{icon}</div>

      <div className={classes.contentSection}>
        <div className={classes.contentIconSection}>
          <div
            className={`${classes.contentIcon} ${
              isoffShore && classes.isoffShore
            }`}
          >
            {icon}
          </div>
          <p className={classes.title}>{title}</p>
        </div>

        <p className={classes.content}>{description}</p>
      </div>
    </div>
  );
};

export default AdditionalServiceItem;
