const DropGainDownIcon = ({ dropDown, color, height, width }) => {
  return (
    <svg
      width={width ? width : "8"}
      height={height ? height : "5"}
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: dropDown ? "rotate(180deg)" : "" }}
    >
      <path
        d="M7 1L4.35355 3.64645C4.15829 3.84171 3.84171 3.84171 3.64645 3.64645L1 1"
        stroke={color ? color : "white"}
        stroke-linecap="round"
      />
    </svg>
  );
};

export default DropGainDownIcon;
