import React from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import PageBody from "../components/TermsOfUse/PageBody";

const TermOfUse = () => {
  return (
    <WebsiteLayout>
      <PageBody />
    </WebsiteLayout>
  );
};

export default TermOfUse;
