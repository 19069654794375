import { useEffect, useState } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState();

  const setMedia = () => {
    let { matches } = window.matchMedia("(max-width: 768px)");
    setIsMobile(matches);
  };

  useEffect(() => {
    setMedia();
    window.addEventListener("resize", setMedia);

    return () => window.removeEventListener("resize", setMedia);
  }, []);

  return {
    isMobile,
  };
};

export default useIsMobile;
