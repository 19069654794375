import useIsMobile from '../../hooks/useIsMobile';
import classes from './NewsContent.module.css'
const newsData = [
  {
    img: "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-site/empowering_mid_sized_hedge_funds_img.png",
    title: "Empowering Mid-Sized Hedge Funds: The Unique Financial White Glove Expertise of Prime Bridge",
    desc: "Tailored services to mid-sized hedge funds, family offices, funds of funds, and high-net-worth individuals.",
    date: "September 12, 2023",
    subImg: "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-site/image-27.png",
    subtitle: "Apple News",
    link: "https://apple.news/AcA8hedxGQJWvYee05tl0XQ"
  },
  {
    img: "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-site/revolutionizing_img.png",
    title: "Revolutionizing Financial White Glove Services For Hedge Funds, Family Offices, and HNWI",
    desc: "Prime Bridge sets itself apart by leveraging innovative technology, and predictive analytics to maximize market efficiency.",
    date: "August 10, 2023",
    subImg: "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-site/image-25.png",
    subtitle: "CEO Weekly",
    link: "https://ceoweekly.com/revolutionizing-financial-white-glove-services-prime-bridge-serves-mid-sized-hedge-funds-family-offices-and-high-net-worth-individuals/"
  },
  {
    img: "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-site/lucrative_img.png",
    title: "Lucrative Liquidity: Prime Bridge Sets a New Standard for Return on Investment",
    desc: "Financial white glove service provider Prime Bridge, founded by Shaun Opoku, is restoring the belief that finance can go further.",
    date: "July 10, 2023",
    subImg: "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-site/image-23.png",
    subtitle: "Venture Capital Post",
    link:"https://www.vcpost.com/articles/124055/20230710/lucrative-liquidity-prime-bridge-sets-a-new-standard-for-return-on-investment.htm"
  },
  {
    img: "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-site/maximizing_img.png",
    title: "How Prime Bridge is Maximizing Market Efficiency For Mid-Sized Hedge Funds",
    desc: "Aiming to help mid-sized hedge funds and high-net-worth individuals face unique financial challenges.",
    date: "June 12, 2023",
    subImg: "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-site/image-26.png",
    subtitle: "Digital Journal",
    link:"https://www.digitaljournal.com/business/how-prime-bridge-is-maximizing-market-efficiency-for-mid-sized-hedge-funds"
  }
]

const NewsContent = () =>{
    
    return(
        <div className={classes.wrapper}>
       {newsData.map((item, i) => (<div className={`row ${classes.newWrapper}`} key={i}>
          <div className='col-md-6'>
          <div
                className={classes.imageContainer}
                style={{
                  backgroundImage: `url(${item.img})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50% 50%",
                  backgroundSize: "cover",
                }}
              ></div>
          </div>
          <div className='col-md-6 ps-md-5'>
          <a href={item.link}><h3>{item.title}</h3></a>
            <p>{item.desc}</p>
            <div className='mt-4 d-flex gap-3 align-items-center'>
              <div className={classes.subImageContainer}
               style={{
                backgroundImage: `url(${item.subImg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
                backgroundSize: "cover",
              }}></div>
              <div>
                <h5 style={{color: "black"}}>{item.subtitle}</h5>
                <h5>{item.date}</h5>
              </div>
            </div>
          </div>
        </div>))}
        </div>
    )
}

export default NewsContent