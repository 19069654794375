import React, { useEffect } from "react";
import Footer from "../shared/Footer";
import NavBar from "../shared/NavBar";
import { useLocation } from "react-router-dom";

const WebsiteLayout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <NavBar />
      {children}
      <Footer />
    </>
  );
};

export default WebsiteLayout;
