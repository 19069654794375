import React, { useState } from "react";
import classes from "./contact-form.module.css";
import ReactFlagsSelect from "react-flags-select";
import useIsMobile from "../../hooks/useIsMobile";
import { useForm } from "react-hook-form";
import { countryListAllIsoData } from "../../constants/countrylist";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { env } from "../../constants/env";


const countryData = [
  {
    "country": "Afghanistan",
    "code": 93
  },
  {
    "country": "Albania",
    "code": 355
  },
  {
    "country": "Algeria",
    "code": 213
  },
  {
    "country": "American Samoa",
    "code": "1-684"
  },
  {
    "country": "Andorra",
    "code": 376
  },
  {
    "country": "Angola",
    "code": 244
  },
  {
    "country": "Anguilla",
    "code": "1-264"
  },
  {
    "country": "Antarctica",
    "code": 672
  },
  {
    "country": "Antigua and Barbuda",
    "code": "1-268"
  },
  {
    "country": "Argentina",
    "code": 54
  },
  {
    "country": "Armenia",
    "code": 374
  },
  {
    "country": "Aruba",
    "code": 297
  },
  {
    "country": "Australia",
    "code": 61
  },
  {
    "country": "Austria",
    "code": 43
  },
  {
    "country": "Azerbaijan",
    "code": 994
  },
  {
    "country": "Bahamas",
    "code": "1-242"
  },
  {
    "country": "Bahrain",
    "code": 973
  },
  {
    "country": "Bangladesh",
    "code": 880
  },
  {
    "country": "Barbados",
    "code": "1-246"
  },
  {
    "country": "Belarus",
    "code": 375
  },
  {
    "country": "Belgium",
    "code": 32
  },
  {
    "country": "Belize",
    "code": 501
  },
  {
    "country": "Benin",
    "code": 229
  },
  {
    "country": "Bermuda",
    "code": "1-441"
  },
  {
    "country": "Bhutan",
    "code": 975
  },
  {
    "country": "Bolivia",
    "code": 591
  },
  {
    "country": "Bosnia and Herzegovina",
    "code": 387
  },
  {
    "country": "Botswana",
    "code": 267
  },
  {
    "country": "Brazil",
    "code": 55
  },
  {
    "country": "British Indian Ocean Territory",
    "code": 246
  },
  {
    "country": "British Virgin Islands",
    "code": "1-284"
  },
  {
    "country": "Brunei",
    "code": 673
  },
  {
    "country": "Bulgaria",
    "code": 359
  },
  {
    "country": "Burkina Faso",
    "code": 226
  },
  {
    "country": "Burundi",
    "code": 257
  },
  {
    "country": "Cambodia",
    "code": 855
  },
  {
    "country": "Cameroon",
    "code": 237
  },
  {
    "country": "Canada",
    "code": 1
  },
  {
    "country": "Cape Verde",
    "code": 238
  },
  {
    "country": "Cayman Islands",
    "code": "1-345"
  },
  {
    "country": "Central African Republic",
    "code": 236
  },
  {
    "country": "Chad",
    "code": 235
  },
  {
    "country": "Chile",
    "code": 56
  },
  {
    "country": "China",
    "code": 86
  },
  {
    "country": "Christmas Island",
    "code": 61
  },
  {
    "country": "Cocos Islands",
    "code": 61
  },
  {
    "country": "Colombia",
    "code": 57
  },
  {
    "country": "Comoros",
    "code": 269
  },
  {
    "country": "Cook Islands",
    "code": 682
  },
  {
    "country": "Costa Rica",
    "code": 506
  },
  {
    "country": "Croatia",
    "code": 385
  },
  {
    "country": "Cuba",
    "code": 53
  },
  {
    "country": "Curacao",
    "code": 599
  },
  {
    "country": "Cyprus",
    "code": 357
  },
  {
    "country": "Czech Republic",
    "code": 420
  },
  {
    "country": "Democratic Republic of the Congo",
    "code": 243
  },
  {
    "country": "Denmark",
    "code": 45
  },
  {
    "country": "Djibouti",
    "code": 253
  },
  {
    "country": "Dominica",
    "code": "1-767"
  },
  {
    "country": "Dominican Republic",
    "code": "1-809, 1-829, 1-849"
  },
  {
    "country": "East Timor",
    "code": 670
  },
  {
    "country": "Ecuador",
    "code": 593
  },
  {
    "country": "Egypt",
    "code": 20
  },
  {
    "country": "El Salvador",
    "code": 503
  },
  {
    "country": "Equatorial Guinea",
    "code": 240
  },
  {
    "country": "Eritrea",
    "code": 291
  },
  {
    "country": "Estonia",
    "code": 372
  },
  {
    "country": "Ethiopia",
    "code": 251
  },
  {
    "country": "Falkland Islands",
    "code": 500
  },
  {
    "country": "Faroe Islands",
    "code": 298
  },
  {
    "country": "Fiji",
    "code": 679
  },
  {
    "country": "Finland",
    "code": 358
  },
  {
    "country": "France",
    "code": 33
  },
  {
    "country": "French Polynesia",
    "code": 689
  },
  {
    "country": "Gabon",
    "code": 241
  },
  {
    "country": "Gambia",
    "code": 220
  },
  {
    "country": "Georgia",
    "code": 995
  },
  {
    "country": "Germany",
    "code": 49
  },
  {
    "country": "Ghana",
    "code": 233
  },
  {
    "country": "Gibraltar",
    "code": 350
  },
  {
    "country": "Greece",
    "code": 30
  },
  {
    "country": "Greenland",
    "code": 299
  },
  {
    "country": "Grenada",
    "code": "1-473"
  },
  {
    "country": "Guam",
    "code": "1-671"
  },
  {
    "country": "Guatemala",
    "code": 502
  },
  {
    "country": "Guernsey",
    "code": "44-1481"
  },
  {
    "country": "Guinea",
    "code": 224
  },
  {
    "country": "Guinea-Bissau",
    "code": 245
  },
  {
    "country": "Guyana",
    "code": 592
  },
  {
    "country": "Haiti",
    "code": 509
  },
  {
    "country": "Honduras",
    "code": 504
  },
  {
    "country": "Hong Kong",
    "code": 852
  },
  {
    "country": "Hungary",
    "code": 36
  },
  {
    "country": "Iceland",
    "code": 354
  },
  {
    "country": "India",
    "code": 91
  },
  {
    "country": "Indonesia",
    "code": 62
  },
  {
    "country": "Iran",
    "code": 98
  },
  {
    "country": "Iraq",
    "code": 964
  },
  {
    "country": "Ireland",
    "code": 353
  },
  {
    "country": "Isle of Man",
    "code": "44-1624"
  },
  {
    "country": "Israel",
    "code": 972
  },
  {
    "country": "Italy",
    "code": 39
  },
  {
    "country": "Ivory Coast",
    "code": 225
  },
  {
    "country": "Jamaica",
    "code": "1-876"
  },
  {
    "country": "Japan",
    "code": 81
  },
  {
    "country": "Jersey",
    "code": "44-1534"
  },
  {
    "country": "Jordan",
    "code": 962
  },
  {
    "country": "Kazakhstan",
    "code": 7
  },
  {
    "country": "Kenya",
    "code": 254
  },
  {
    "country": "Kiribati",
    "code": 686
  },
  {
    "country": "Kosovo",
    "code": 383
  },
  {
    "country": "Kuwait",
    "code": 965
  },
  {
    "country": "Kyrgyzstan",
    "code": 996
  },
  {
    "country": "Laos",
    "code": 856
  },
  {
    "country": "Latvia",
    "code": 371
  },
  {
    "country": "Lebanon",
    "code": 961
  },
  {
    "country": "Lesotho",
    "code": 266
  },
  {
    "country": "Liberia",
    "code": 231
  },
  {
    "country": "Libya",
    "code": 218
  },
  {
    "country": "Liechtenstein",
    "code": 423
  },
  {
    "country": "Lithuania",
    "code": 370
  },
  {
    "country": "Luxembourg",
    "code": 352
  },
  {
    "country": "Macau",
    "code": 853
  },
  {
    "country": "Macedonia",
    "code": 389
  },
  {
    "country": "Madagascar",
    "code": 261
  },
  {
    "country": "Malawi",
    "code": 265
  },
  {
    "country": "Malaysia",
    "code": 60
  },
  {
    "country": "Maldives",
    "code": 960
  },
  {
    "country": "Mali",
    "code": 223
  },
  {
    "country": "Malta",
    "code": 356
  },
  {
    "country": "Marshall Islands",
    "code": 692
  },
  {
    "country": "Mauritania",
    "code": 222
  },
  {
    "country": "Mauritius",
    "code": 230
  },
  {
    "country": "Mayotte",
    "code": 262
  },
  {
    "country": "Mexico",
    "code": 52
  },
  {
    "country": "Micronesia",
    "code": 691
  },
  {
    "country": "Moldova",
    "code": 373
  },
  {
    "country": "Monaco",
    "code": 377
  },
  {
    "country": "Mongolia",
    "code": 976
  },
  {
    "country": "Montenegro",
    "code": 382
  },
  {
    "country": "Montserrat",
    "code": "1-664"
  },
  {
    "country": "Morocco",
    "code": 212
  },
  {
    "country": "Mozambique",
    "code": 258
  },
  {
    "country": "Myanmar",
    "code": 95
  },
  {
    "country": "Namibia",
    "code": 264
  },
  {
    "country": "Nauru",
    "code": 674
  },
  {
    "country": "Nepal",
    "code": 977
  },
  {
    "country": "Netherlands",
    "code": 31
  },
  {
    "country": "Netherlands Antilles",
    "code": 599
  },
  {
    "country": "New Caledonia",
    "code": 687
  },
  {
    "country": "New Zealand",
    "code": 64
  },
  {
    "country": "Nicaragua",
    "code": 505
  },
  {
    "country": "Niger",
    "code": 227
  },
  {
    "country": "Nigeria",
    "code": 234
  },
  {
    "country": "Niue",
    "code": 683
  },
  {
    "country": "North Korea",
    "code": 850
  },
  {
    "country": "Northern Mariana Islands",
    "code": "1-670"
  },
  {
    "country": "Norway",
    "code": 47
  },
  {
    "country": "Oman",
    "code": 968
  },
  {
    "country": "Pakistan",
    "code": 92
  },
  {
    "country": "Palau",
    "code": 680
  },
  {
    "country": "Palestine",
    "code": 970
  },
  {
    "country": "Panama",
    "code": 507
  },
  {
    "country": "Papua New Guinea",
    "code": 675
  },
  {
    "country": "Paraguay",
    "code": 595
  },
  {
    "country": "Peru",
    "code": 51
  },
  {
    "country": "Philippines",
    "code": 63
  },
  {
    "country": "Pitcairn",
    "code": 64
  },
  {
    "country": "Poland",
    "code": 48
  },
  {
    "country": "Portugal",
    "code": 351
  },
  {
    "country": "Puerto Rico",
    "code": "1-787, 1-939"
  },
  {
    "country": "Qatar",
    "code": 974
  },
  {
    "country": "Republic of the Congo",
    "code": 242
  },
  {
    "country": "Reunion",
    "code": 262
  },
  {
    "country": "Romania",
    "code": 40
  },
  {
    "country": "Russia",
    "code": 7
  },
  {
    "country": "Rwanda",
    "code": 250
  },
  {
    "country": "Saint Barthelemy",
    "code": 590
  },
  {
    "country": "Saint Helena",
    "code": 290
  },
  {
    "country": "Saint Kitts and Nevis",
    "code": "1-869"
  },
  {
    "country": "Saint Lucia",
    "code": "1-758"
  },
  {
    "country": "Saint Martin",
    "code": 590
  },
  {
    "country": "Saint Pierre and Miquelon",
    "code": 508
  },
  {
    "country": "Saint Vincent and the Grenadines",
    "code": "1-784"
  },
  {
    "country": "Samoa",
    "code": 685
  },
  {
    "country": "San Marino",
    "code": 378
  },
  {
    "country": "Sao Tome and Principe",
    "code": 239
  },
  {
    "country": "Saudi Arabia",
    "code": 966
  },
  {
    "country": "Senegal",
    "code": 221
  },
  {
    "country": "Serbia",
    "code": 381
  },
  {
    "country": "Seychelles",
    "code": 248
  },
  {
    "country": "Sierra Leone",
    "code": 232
  },
  {
    "country": "Singapore",
    "code": 65
  },
  {
    "country": "Sint Maarten",
    "code": "1-721"
  },
  {
    "country": "Slovakia",
    "code": 421
  },
  {
    "country": "Slovenia",
    "code": 386
  },
  {
    "country": "Solomon Islands",
    "code": 677
  },
  {
    "country": "Somalia",
    "code": 252
  },
  {
    "country": "South Africa",
    "code": 27
  },
  {
    "country": "South Korea",
    "code": 82
  },
  {
    "country": "South Sudan",
    "code": 211
  },
  {
    "country": "Spain",
    "code": 34
  },
  {
    "country": "Sri Lanka",
    "code": 94
  },
  {
    "country": "Sudan",
    "code": 249
  },
  {
    "country": "Suriname",
    "code": 597
  },
  {
    "country": "Svalbard and Jan Mayen",
    "code": 47
  },
  {
    "country": "Swaziland",
    "code": 268
  },
  {
    "country": "Sweden",
    "code": 46
  },
  {
    "country": "Switzerland",
    "code": 41
  },
  {
    "country": "Syria",
    "code": 963
  },
  {
    "country": "Taiwan",
    "code": 886
  },
  {
    "country": "Tajikistan",
    "code": 992
  },
  {
    "country": "Tanzania",
    "code": 255
  },
  {
    "country": "Thailand",
    "code": 66
  },
  {
    "country": "Togo",
    "code": 228
  },
  {
    "country": "Tokelau",
    "code": 690
  },
  {
    "country": "Tonga",
    "code": 676
  },
  {
    "country": "Trinidad and Tobago",
    "code": "1-868"
  },
  {
    "country": "Tunisia",
    "code": 216
  },
  {
    "country": "Turkey",
    "code": 90
  },
  {
    "country": "Turkmenistan",
    "code": 993
  },
  {
    "country": "Turks and Caicos Islands",
    "code": "1-649"
  },
  {
    "country": "Tuvalu",
    "code": 688
  },
  {
    "country": "U.S. Virgin Islands",
    "code": "1-340"
  },
  {
    "country": "Uganda",
    "code": 256
  },
  {
    "country": "Ukraine",
    "code": 380
  },
  {
    "country": "United Arab Emirates",
    "code": 971
  },
  {
    "country": "United Kingdom",
    "code": 44
  },
  {
    "country": "United States",
    "code": 1
  },
  {
    "country": "Uruguay",
    "code": 598
  },
  {
    "country": "Uzbekistan",
    "code": 998
  },
  {
    "country": "Vanuatu",
    "code": 678
  },
  {
    "country": "Vatican",
    "code": 379
  },
  {
    "country": "Venezuela",
    "code": 58
  },
  {
    "country": "Vietnam",
    "code": 84
  },
  {
    "country": "Wallis and Futuna",
    "code": 681
  },
  {
    "country": "Western Sahara",
    "code": 212
  },
  {
    "country": "Yemen",
    "code": 967
  },
  {
    "country": "Zambia",
    "code": 260
  },
  {
    "country": "Zimbabwe",
    "code": 263
  }
]

const ContactForm = () => {
  const [countryCode, setCountryCode] = useState("CA");
  const { isMobile } = useIsMobile();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneCode, setPhoneCode] = useState()

  const getCountryCode = (country_code) => {
    const country = countryListAllIsoData?.find(
      (isoData) => isoData.code == country_code
    );
    return country?.number;
  };

  const countryHandler = (e) => {
    var result = countryData.find(obj => {
      return obj.country === e.target.value
    })

    console.log("COUNTRY OBJECT", result.code)

    setPhoneCode(result.code)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (_data) => {
    const data = {
      ..._data,
      Phone: `+(${phoneCode}) ${_data.Phone}`,
    };
    const formData = new FormData();

    let dataContent = {}

    Object.keys(data)?.forEach((key, i) => {
      // console.log(`TEST FROM ${i}`, data[key]);
      if(i === 0){
        dataContent = {...dataContent,firstName: data[key]}
      }else if(i === 1){
        dataContent = {...dataContent,lastName: data[key]}
      }else if(i === 2){
        dataContent = {...dataContent,email: data[key]}
      }else if(i === 3){
        dataContent = {...dataContent,phone: data[key]}
      }else if(i === 4){
        dataContent = {...dataContent,country: data[key]}
      }else if(i === 5){
        dataContent = {...dataContent,employeesNum: data[key]}
      }else if(i === 6){
        dataContent = {...dataContent,companyName: data[key]}
      }else if(i === 7){
        dataContent = {...dataContent,website: data[key]}
      }else if(i === 8){
        dataContent = {...dataContent,description: data[key]}
      }
      
      formData.append(`data[${key}]`, data[key]);
    });

    setIsSubmitting(true);
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/lead`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();
        console.log("EEERROR", error.error)
        toast.error(error.error);
        setIsSubmitting(false);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();


      setValue("Legal First Name", "");
      setValue("Legal Last Name", "");
      setValue("Email", "");
      setValue("Phone", "");
      setValue("Headquarters", "");
      setValue("Number of employees", "");
      setValue("Company Name", "");
      setValue("Company Website", "");
      setValue("Description", "");
      setValue("Policy", "");
      window?.scrollTo(0, 0);
      toast.success("Successfully submitted your request");

      setIsSubmitting(false);

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
      
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
    
  };

  return (
    <div className={classes.container}>
      <h3 className={classes.title}>Get Started with Prime Bridge</h3>
      <p className={classes.text}>
        We will contact you within 24 business hours
      </p>

      <form
        onSubmit={handleSubmit(async (data) => await onSubmit(data))}
        className={classes.form}
      >
        <div className={classes.company_group}>
          <div className={classes.company_input}>
            <input
              className={classes.form_field}
              {...register("Legal First Name", { required: true })}
              placeholder="Legal first name"
            />

            <p className={classes.form_error}>
              {errors?.["Legal First Name"] && "First name is required"}
            </p>
          </div>

          <div className={classes.company_input}>
            <input
              {...register("Legal Last Name", { required: true })}
              className={classes.form_field}
              placeholder="Legal last name"
            />
            <p className={classes.form_error}>
              {errors?.["Legal Last Name"] && "Last name is required"}
            </p>
          </div>
        </div>
        <div>
          <input
            className={classes.form_field}
            placeholder="Email address"
            type={"email"}
            {...register("Email", { required: true })}
          />
          <p className={classes.form_error}>
            {errors?.Email && "Email is required"}
          </p>
        </div>
        <div>
          <div className={classes.phone_input}>
            <div>
              <ReactFlagsSelect
                selected={countryCode}
                onSelect={(code) => setCountryCode(code)}
                showSelectedLabel={false}
                fullWidth={true}
                selectedSize={isMobile ? 40 : 35}
                selectButtonClassName={classes.menu_flags_button}
                className={classes.menu_flags}
                rfsKey="app-lang"
              />
            </div>
            <input
              className={classes.form_field}
              placeholder="Phone number"
              type="tel"
              {...register("Phone", { required: true })}
              onChange={(e) => {
                setValue("Phone", e.target.value.replace(/[^\d,]/g, ""));
              }}
            />
          </div>
          <p className={classes.form_error}>
            {errors?.["Phone"] && "Phone number is required"}
          </p>
        </div>
        <div className={classes.company_group}>
          <div
            className={`${classes.select_container} ${classes.company_input}`}
          >
            <select
              className={classes.form_select}
              name=""
              id=""
              required
              {...register("Headquarters", { required: true })}
              onChange={countryHandler}
            >
              <option className="placeholder" disabled selected hidden value="">
                Headquarters (Country)
              </option>
              {countryData.map((item, i) => (<option className="placeholder" value={item.country} key={i}>
                {item.country}
              </option>))}
              {/* <option className="placeholder" value="UK">
                UK
              </option>
              <option className="placeholder" value="USA">
                USA
              </option> */}
            </select>
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.select_btn}
            >
              <path
                opacity="0.5"
                d="M10.7168 2.11011L6.68885 6.0369C6.49112 6.22966 6.17457 6.22564 5.9818 6.02791L2.05501 1.99996"
                stroke="#9C9C9C"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <p className={classes.form_error}>
              {errors?.Headquarters && "Headqurters is required"}
            </p>
          </div>

          <div
            className={`${classes.select_container} ${classes.company_input}`}
          >
            <select
              className={classes.form_select}
              name=""
              id=""
              required
              {...register("Number of employees", { required: true })}
            >
              <option value="" disabled selected hidden>
                Number of employees
              </option>
              <option value="0 - 10">0 - 10 </option>
              <option value="100- 1000">100- 1000</option>
              <option value="1000+">1000+</option>
            </select>
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.select_btn}
            >
              <path
                opacity="0.5"
                d="M10.7168 2.11011L6.68885 6.0369C6.49112 6.22966 6.17457 6.22564 5.9818 6.02791L2.05501 1.99996"
                stroke="#9C9C9C"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <p className={classes.form_error}>
              {errors?.["Number of employees"] &&
                "Number of employees is required"}
            </p>
          </div>
        </div>

        <div className={classes.company_group}>
          <div className={classes.company_input}>
            <input
              {...register("Company Name", { required: true })}
              className={classes.form_field}
              placeholder="Company Name"
            />
            <p className={classes.form_error}>
              {errors?.["Company Name"] && "Company Name is required"}
            </p>
          </div>
          <div className={classes.company_input}>
            <input
              {...register("Company Website", { required: true })}
              className={classes.form_field}
              placeholder="Company Website"
            />
            <p className={classes.form_error}>
              {errors?.["Company Website"] && "Company Website is required"}
            </p>
          </div>
        </div>
        <div>
          <textarea
            placeholder="Short description of your company"
            className={classes.form_textarea}
            name=""
            id=""
            {...register("Description", { required: true })}
          ></textarea>
          <p className={classes.form_error}>
            {errors?.Description && "Description is required"}
          </p>
        </div>

        <div
          style={{ marginLeft: "2rem" }}
          className="d-flex align-items-center"
        >
          <label class="container">
            <input
              {...register("Policy", { required: true })}
              type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
          <div className={classes.policyContainer}>
            <p className={classes.policy_text}>
              We respect your data. By submitting this form, you agree that we
              may use this information in accordance with our{" "}
              <Link
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                to={"https://www.primebridge.io/privacy"}
              >
                <span className={classes.policy}>Privacy Policy.</span>
              </Link>
            </p>
          </div>
          <p className={classes.form_error}>
            {errors?.poliicy && "Please read and agree to this policy"}
          </p>
        </div>

        <div className={classes.btnContainer}>
          <button disabled={isSubmitting} className={classes.btn}>
            {!isSubmitting ? "Book a Demo" : "Booking"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
