import React, { useEffect } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Stats from "../components/Service/Stats";
import Works from "../components/Home/Works";
import { Helmet } from "react-helmet";
import Hero from "../components/Partners/Hero";

const Partners = () => {
  useEffect(() => {
    document.title = "Our Custody and Brokerage Partners | Prime Bridge";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Discover our esteemed partners, Prime Custody and Prime Markets for security and seamless access to global financial markets."
        />
      </Helmet>
      <Hero />
      <Stats />
      <Works />
    </WebsiteLayout>
  );
};

export default Partners;