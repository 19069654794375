import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import classes from "./work.module.css";
import useIsMobile from "../../hooks/useIsMobile";

const Works = () => {
  const videoRef = useRef();

  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (!isMobile) {
      videoRef?.current?.play();
    }
  }, []);
  return (
    <div className={classes.container}>
      <video
        webkit-playsinline={true}
        playsInline
        ref={videoRef}
        muted
        loop
        className={classes.myVideo}
        width={"1000"}
        height={"1000"}
      >
        <source
          src="https://s3.amazonaws.com/cdn.traders-central/prime-bridge-portal/PBVideo2.mp4"
          type="video/mp4"
        />
      </video>
      <div style={{ position: "relative" }} className={classes.cardContainer}>
        <p className={classes.heading}>Get started</p>
        <div className={classes.card}>
          <div className={classes.iconContainer}>
            <svg
              width="25"
              height="22"
              viewBox="0 0 30 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.50001 6C6.67158 6 6 6.67157 6 7.5C6 8.32843 6.67158 9 7.50001 9H16.5C17.3284 9 18 8.32843 18 7.5C18 6.67157 17.3284 6 16.5 6H7.50001Z"
                fill="white"
              />
              <path
                d="M6 13.5C6 12.6716 6.67158 12 7.50001 12H16.5C17.3284 12 18 12.6716 18 13.5C18 14.3284 17.3284 15 16.5 15H7.50001C6.67158 15 6 14.3284 6 13.5Z"
                fill="white"
              />
              <path
                d="M7.50001 18C6.67158 18 6 18.6716 6 19.5C6 20.3284 6.67158 21 7.50001 21H16.5C17.3284 21 18 20.3284 18 19.5C18 18.6716 17.3284 18 16.5 18H7.50001Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.4375 27H24.5625C27.5877 27 30 24.5152 30 21.5V5.5C30 2.48479 27.5877 0 24.5625 0H5.4375C2.41227 0 0 2.48479 0 5.5V21.5C0 24.5152 2.41227 27 5.4375 27ZM5.4375 3C4.11349 3 3 4.09693 3 5.5V21.5C3 22.9031 4.11349 24 5.4375 24H18.5625C19.8865 24 21 22.9031 21 21.5V5.5C21 4.09693 19.8865 3 18.5625 3H5.4375ZM24.5625 24H23.4063C23.7863 23.248 24 22.3975 24 21.5V5.5C24 4.60249 23.7863 3.75197 23.4063 3H24.5625C25.8865 3 27 4.09693 27 5.5V21.5C27 22.9031 25.8865 24 24.5625 24Z"
                fill="white"
              />
            </svg>
          </div>

          <p className={classes.cardText}>
            Fill in the needed details to request a demo
          </p>
        </div>
        <div className={classes.card}>
          <div className={classes.iconContainer}>
            <svg
              width="31"
              height="28"
              viewBox="0 0 31 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{flexShrink: 0}}
            >
              <path
                d="M8 2C4.68629 2 2 4.68609 2 7.99956V15.8963C2 19.2098 4.68629 21.8959 8 21.8959L8 25.8497C8 25.9751 8.14459 26.0451 8.24295 25.9674L13.4 21.8959H23C26.3137 21.8959 29 19.2098 29 15.8963V7.99956C29 4.68609 26.3137 2 23 2H8Z"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M9.5 14.1C10.3837 14.1 11.1 13.3837 11.1 12.5C11.1 11.6163 10.3837 10.9 9.5 10.9C8.61634 10.9 7.9 11.6163 7.9 12.5C7.9 13.3837 8.61634 14.1 9.5 14.1ZM15.5 14.1C16.3837 14.1 17.1 13.3837 17.1 12.5C17.1 11.6163 16.3837 10.9 15.5 10.9C14.6163 10.9 13.9 11.6163 13.9 12.5C13.9 13.3837 14.6163 14.1 15.5 14.1ZM21.5 14.1C22.3837 14.1 23.1 13.3837 23.1 12.5C23.1 11.6163 22.3837 10.9 21.5 10.9C20.6163 10.9 19.9 11.6163 19.9 12.5C19.9 13.3837 20.6163 14.1 21.5 14.1Z"
                fill="white"
                stroke="white"
                stroke-width="0.2"
              />
            </svg>
          </div>

          <p className={classes.cardText}>
            A sales manager will schedule a call to run you through the details
          </p>
        </div>
        <div className={classes.card}>
          <div className={classes.iconContainer}>
            <svg
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.3333 10.7916C13.1847 10.7916 13.8749 11.4818 13.8749 12.3333V24.6666C13.8749 25.518 13.1847 26.2083 12.3333 26.2083C11.4818 26.2083 10.7916 25.518 10.7916 24.6666V12.3333C10.7916 11.4818 11.4818 10.7916 12.3333 10.7916Z"
                fill="white"
              />
              <path
                d="M26.2083 24.6666V12.3333C26.2083 11.4818 25.518 10.7916 24.6666 10.7916C23.8151 10.7916 23.1249 11.4818 23.1249 12.3333V24.6666C23.1249 25.518 23.8151 26.2083 24.6666 26.2083C25.518 26.2083 26.2083 25.518 26.2083 24.6666Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.9351 3.08325C6.15093 3.08325 3.08325 6.15093 3.08325 9.9351V27.0647C3.08325 30.8489 6.15093 33.9166 9.9351 33.9166H27.0647C30.8489 33.9166 33.9166 30.8489 33.9166 27.0647V9.9351C33.9166 6.15093 30.8489 3.08325 27.0647 3.08325H9.9351ZM27.0647 6.16659H9.9351C7.85381 6.16659 6.16659 7.85381 6.16659 9.9351V27.0647C6.16659 29.146 7.85381 30.8333 9.9351 30.8333H27.0647C29.146 30.8333 30.8333 29.146 30.8333 27.0647V9.9351C30.8333 7.85381 29.146 6.16659 27.0647 6.16659Z"
                fill="white"
              />
              <path
                d="M24.6667 22.5834C26.0705 22.5834 27.2084 21.4455 27.2084 20.0417C27.2084 18.638 26.0705 17.5001 24.6667 17.5001C23.263 17.5001 22.1251 18.638 22.1251 20.0417C22.1251 21.4455 23.263 22.5834 24.6667 22.5834ZM12.3334 19.5001C13.7371 19.5001 14.8751 18.3621 14.8751 16.9584C14.8751 15.5547 13.7371 14.4167 12.3334 14.4167C10.9297 14.4167 9.79175 15.5547 9.79175 16.9584C9.79175 18.3621 10.9297 19.5001 12.3334 19.5001Z"
                fill="#0077B5"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </div>

          <p className={classes.cardText}>
            Build your bespoke package and sign your contract
          </p>
        </div>
        <div className={classes.demoContainer}>
          <Link style={{ textDecoration: "none" }} to={"/request-demo"}>
            <button className={classes.demoBtn}>
              Request a Demo
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.8499 11.2389C23.7547 10.9936 23.6119 10.7695 23.4297 10.5795L13.4256 0.587699C13.239 0.401376 13.0176 0.253576 12.7738 0.152739C12.5301 0.051901 12.2688 0 12.005 0C11.4722 0 10.9612 0.211402 10.5844 0.587699C10.3978 0.774023 10.2499 0.995221 10.1489 1.23866C10.0479 1.48211 9.99597 1.74303 9.99597 2.00653C9.99597 2.53869 10.2076 3.04906 10.5844 3.42536L17.1871 9.99994H2.00083C1.47018 9.99994 0.961259 10.2105 0.58603 10.5852C0.210802 10.96 0 11.4683 0 11.9983C0 12.5283 0.210802 13.0366 0.58603 13.4113C0.961259 13.7861 1.47018 13.9966 2.00083 13.9966H17.1871L10.5844 20.5712C10.3969 20.757 10.248 20.978 10.1464 21.2215C10.0449 21.4651 9.99256 21.7263 9.99256 21.9901C9.99256 22.2539 10.0449 22.5151 10.1464 22.7586C10.248 23.0021 10.3969 23.2231 10.5844 23.4089C10.7704 23.5962 10.9917 23.7449 11.2355 23.8463C11.4793 23.9478 11.7409 24 12.005 24C12.2691 24 12.5306 23.9478 12.7745 23.8463C13.0183 23.7449 13.2396 23.5962 13.4256 23.4089L23.4297 13.4171C23.6119 13.2271 23.7547 13.003 23.8499 12.7577C24.05 12.2711 24.05 11.7254 23.8499 11.2389Z"
                  fill="#0077B5"
                />
              </svg>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Works;
