import React, { useEffect } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import PageBody from "../components/Liquidity/PageBody";
import { Helmet } from "react-helmet";

const Liquidity = () => {
  useEffect(() => {
    document.title = "Liquidity Solutions | Prime Bridge";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Prime Bridge offers innovative liquidity solutions designed to help hedge funds and high net worth individuals navigate an evolving market. Learn more today."
        />
      </Helmet>
      <PageBody />
    </WebsiteLayout>
  );
};

export default Liquidity;
