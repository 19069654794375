import React, { useRef, useEffect } from "react";
import classes from "./hero.module.css";
import { Link } from "react-router-dom";

const Hero = () => {
  const videoRef = useRef();

  useEffect(() => {
    videoRef?.current?.play();
  }, []);
  return (
    <div className={classes.hero}>
      <video
        webkit-playsinline={true}
        playsInline
        ref={videoRef}
        muted
        loop
        className={classes.myVideo}
        width={"1000"}
        height={"1000"}
      >
        <source
          src="https://s3.amazonaws.com/cdn.traders-central/prime-bridge-portal/Home-page-video.mp4"
          type="video/mp4"
        />
      </video>
      <div style={{ position: "relative" }}>
        <h2 className={classes.title}>
          Innovative liquidity solutions for a rapidly evolving market
        </h2>
        <div className={classes.discoverBtnContainer}>
          <Link style={{ textDecoration: "none" }} to={"/services"}>
            <button className={classes.discoverBtn}>
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 3.5C11.7239 3.5 13.3772 4.18482 14.5962 5.40381C15.8152 6.62279 16.5 8.27609 16.5 10C16.5 11.61 15.91 13.09 14.94 14.23L15.21 14.5H16L21 19.5L19.5 21L14.5 16V15.21L14.23 14.94C13.0505 15.9468 11.5507 16.4999 10 16.5C8.27609 16.5 6.62279 15.8152 5.40381 14.5962C4.18482 13.3772 3.5 11.7239 3.5 10C3.5 8.27609 4.18482 6.62279 5.40381 5.40381C6.62279 4.18482 8.27609 3.5 10 3.5ZM10 5.5C7.5 5.5 5.5 7.5 5.5 10C5.5 12.5 7.5 14.5 10 14.5C12.5 14.5 14.5 12.5 14.5 10C14.5 7.5 12.5 5.5 10 5.5Z"
                  fill="#01486D"
                />
              </svg>

              <p>Discover our Services</p>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
