import React from "react";
import classes from "./article-1.module.css";
import { Link } from "react-router-dom";

const Article3Body = () => {
  return (
    <div className={classes.container}>
      <div className={classes.hero}>
        <div className={classes.content}>
          <Link to={"/learn"} style={{ textDecoration: "none" }}>
            <p className={classes.sectionTitle}>Learn</p>
          </Link>

          <h2 className={classes.heading}>
            The Benefits of Offshore Corporation Formation for Your Business{" "}
          </h2>

          <p className={classes.subTitle}>
            Learn more about this legal strategy that allows businesses to
            establish a corporate entity in a foreign country.
          </p>
        </div>

        {/* <div className={classes.image}>
          <img src="/images/article-3.png" />
        </div> */}
      </div>

      <div className={classes.contentSection}>
        <p className={classes.content}>
          Offshore corporation formation is a legal strategy that allows
          businesses to establish a corporate entity in a foreign country,
          outside of the country where the business is headquartered. The
          practice has gained popularity in recent years due to the numerous
          benefits that it offers to businesses of all sizes. In this article,
          we will explore some of the benefits of offshore corporation formation
          and how it can help your business.
        </p>

        <div className={classes.contentContainer}>
          <p className={classes.title}>Tax Benefits</p>
          <p className={classes.content}>
            One of the primary reasons that businesses choose to form an
            offshore corporation is to take advantage of the tax benefits that
            it offers. Many offshore jurisdictions have lower tax rates than the
            home country of the business, allowing them to save money on taxes.
            Additionally, offshore corporations can benefit from exemptions,
            deductions, and other tax incentives that are not available in their
            home country.
          </p>
        </div>

        <div className={classes.contentContainer}>
          <p className={classes.title}>Asset Protection</p>
          <p className={classes.content}>
            Offshore corporation formation also provides businesses with a level
            of asset protection. When a business forms an offshore corporation,
            it creates a separate legal entity that is distinct from the owners
            and the business itself. This means that the assets of the
            corporation are separate from the personal assets of the owners. In
            the event of legal action or other liabilities, the assets of the
            corporation are protected from seizure or other legal action
          </p>
        </div>

        <div className={classes.contentContainer}>
          <p className={classes.title}>Increased Privacy</p>
          <p className={classes.content}>
            Offshore corporation formation also provides businesses with
            increased privacy. Many offshore jurisdictions have strict privacy
            laws that protect the identity of the owners of the corporation.
            This means that the owners can conduct business without revealing
            their identity, providing an extra layer of privacy and protection.
          </p>
        </div>

        <div className={classes.contentContainer}>
          <p className={classes.title}>Access to Global Markets</p>
          <p className={classes.content}>
            Offshore corporation formation also provides businesses with access
            to global markets. Many offshore jurisdictions have established
            business-friendly environments that attract foreign investment. By
            forming an offshore corporation, businesses can tap into these
            markets and expand their operations globally.
          </p>
        </div>

        <div className={classes.contentContainer}>
          <p className={classes.title}>Lower Operating Costs</p>
          <p className={classes.content}>
            Offshore corporation formation can also help businesses to lower
            their operating costs. Many offshore jurisdictions have lower labor
            costs, rental costs, and other expenses compared to the home country
            of the business. By establishing an offshore corporation, businesses
            can take advantage of these cost savings and improve their bottom
            line.
          </p>
        </div>

        <p className={classes.content}>
          To get our assistance to form your offshore business,{" "}
          <Link to={"/request-demo"}>
            <span className={classes.underline}>
              reach out to our sales manager here
            </span>
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default Article3Body;
