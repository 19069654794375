import React from "react";
import classes from "./hero.module.css";

const Hero = () => {
  return (
    <div className={classes.container}>
      <div className={classes.heading_container}>
        <p className={classes.heading}>Bridging the gap between </p>
        <p className={`${classes.heading} ${classes.underlined}`}>
          liquidity and market making needs
        </p>
      </div>
    </div>
  );
};

export default Hero;
