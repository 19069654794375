import React, { useEffect, useState } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Hero from "../components/Performance/Hero";
import Compare from "../components/Performance/Compare";
import Returns from "../components/Performance/Returns";
import Work from "../components/Performance/Work";
import { Helmet } from "react-helmet";

const Performance = () => {
  useEffect(() => {
    document.title = "Our Clients Returns vs Indexes | Prime Bridge";
  }, []);

  const [active, setActive] = useState(0)

  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Compare your returns with Prime Bridge's liquidity solutions to the SP500 index. Discover how our liquidity solutions can benefit your business."
        />
      </Helmet>
      <Hero />
      <Compare active={active} setActive={(item) => setActive(item)}/>
      <Returns active={active} setActive={(item) => setActive(item)}/>
      <Work />
    </WebsiteLayout>
  );
};

export default Performance;
