import React from "react";
import classes from "./importance-card.module.css";

const ImportanceCard = ({ heading, message, fade }) => {
  return (
    <div className={classes.container}>
      <h4 className={classes.heading}>{heading}</h4>
      <p className={classes.message}>{message}</p>
    </div>
  );
};

export default ImportanceCard;
