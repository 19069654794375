import React, { useEffect } from "react";
import Empowering from "../components/About/Empowering";
import Hero from "../components/About/Hero";
import Importance from "../components/About/Importance";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Stats from "../components/Service/Stats";
import Works from "../components/Home/Works";
import MeetFounder from "../components/About/MeetFounder";
import { Helmet } from "react-helmet";
import MainWithdrawalFees from "../components/WithdrawalFees/MainWithdrawalFees";

const WithdrawalFees = () => {
  useEffect(() => {
    document.title = "About Prime Bridge | Withdrawal Fees";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Withdrawal Fees"
        />
      </Helmet>
      <MainWithdrawalFees />
    </WebsiteLayout>
  );
};

export default WithdrawalFees;
