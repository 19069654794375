import Dashboard from "./components/Dashboard/Dashboard";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";
import RequestDemo from "./pages/RequestDemo";
import About from "./pages/About";
import Service from "./pages/Service";
import { Toaster } from "react-hot-toast";
import TermOfUse from "./pages/TermOfUse";
import Privacy from "./pages/Privacy";
import Disclosure from "./pages/Disclosure";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import Liquidity from "./pages/Liquidity";
import MarketMaking from "./pages/MarketMaking";
import AdditionalServices from "./pages/AdditionalServices";
import Learn from "./pages/Learn";
import Article1 from "./pages/Article-1";
import Article2 from "./pages/Article-2";
import Article3 from "./pages/Article-3";
import Article5 from "./pages/Article-5";
import Article4 from "./pages/Article-4";
import Article6 from "./pages/Article-6";
import Article0 from "./pages/Article-0";
import Performance from "./pages/Performance";
import Partners from "./pages/Partners";
import { env } from "./constants/env";
import axios from "axios";
import Loading from "./components/Loading";
import WithdrawalFees from "./pages/WithdrawalFees";
import News from "./pages/News";

function App() {
  const [userIP, setUserIP] = useState()
  const [country, setCountry] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isBanned, setIsBanned] = useState()

  const geoBanCheck = async () => {
      try {
        const ipData = await axios.get(
          `https://pro.ip-api.com/json/?key=${env.ipAPIKey}`
        );
        console.log("IP DATA",ipData.data)

        console.log("COUNTRY", ipData.data.regionName)

        setUserIP(ipData.data.query)
        setCountry(ipData.data.country);
        if(ipData.data.query === "71.231.167.226"){
          setIsBanned(false);
        }else if (
          ipData.data.regionName === "British Columbia" 
        ) {
          setIsBanned(true);
        }
        setIsLoading(false);
      } catch (err) {
        alert(err.message);
        setIsLoading(false);
      }
    };

    useEffect(() => {
      geoBanCheck()
    },[])  

  const router = createBrowserRouter([
    {
      path: "/",
      // errorElement: <ErrorPage />,
      element: <Home />,
    },
    {
      path: "request-demo",
      element: <RequestDemo />,
    },
    {
      path: "about",
      element: <About />,
    },
    {
      path: "services",
      element: <Service />,
    },
    {
      path: "terms-of-use",
      element: <TermOfUse />,
    },
    {
      path: "privacy",
      element: <Privacy />,
    },
    {
      path: "disclosure",
      element: <Disclosure />,
    },

    {
      path: "/services/liquidity",
      element: <Liquidity />,
    },
    {
      path: "/services/market-making",
      element: <MarketMaking />,
    },
    {
      path: "/services/additional-services",
      element: <AdditionalServices />,
    },
    {
      path: "learn",
      element: <Learn />,
    },
    {
      path: "learn/1",
      element: <Article1 />,
    },
    {
      path: "learn/2",
      element: <Article2 />,
    },
    {
      path: "learn/3",
      element: <Article3 />,
    },
    {
      path: "learn/5",
      element: <Article5 />,
    },
    {
      path: "learn/4",
      element: <Article4 />,
    },

    {
      path: "learn/6",
      element: <Article6 />,
    },

    {
      path: "learn/0",
      element: <Article0 />,
    },
    {
      path: "performance",
      element: <Performance />,
    },
    {
      path: "partners",
      element: <Partners />,
    },
    {
      path: "news",
      element: <News />,
    },
    {
      path: "withdrawal-fees",
      element: <WithdrawalFees />,
    },
  ]);

  const defaultRoute = (<>
  <Toaster />
  <RouterProvider router={router} />
  </>)

  useEffect(() => {
    AOS.init({
      offset: 120, // offset (in px) from the original trigger point
      delay: 200, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false,
    });
  }, []);
  return (
    <>
    {isLoading === true ? (
          <Loading />
        ) : (userIP === "24.86.205.18" || userIP === "23.16.218.118" || userIP === "104.28.116.21" || userIP === "204.101.247.162"  || userIP === "66.244.218.155" || userIP === "71.231.167.226" || userIP === "174.6.112.205" || userIP === "162.156.101.42") ? defaultRoute : (country === "United States" || country === "Canada") ?
      <div className="d-flex justify-content-center align-items-center vh-100">
        <h2>Service not available in your region</h2>
      </div> 
      :
      defaultRoute
      }
    </>
  );
}

export default App;
