import React from "react";
import classes from "./article-1.module.css";
import { Link } from "react-router-dom";

const Article1Body = () => {
  return (
    <div className={classes.container}>
      <div className={classes.hero}>
        <div className={classes.content}>
          <Link to={"/learn"} style={{ textDecoration: "none" }}>
            <p className={classes.sectionTitle}>Learn</p>
          </Link>

          <h2 className={classes.heading}>
            Market Making and its Role in Financial Markets
          </h2>

          <p className={classes.subTitle}>
            How market makers provide liquidity to the market, reduce
            volatility, and help to ensure fair pricing for financial
            instruments.
          </p>
        </div>
        {/* 
        <div className={classes.image}>
          <img src="/images/article-1.png" />
        </div> */}
      </div>

      <div className={classes.contentSection}>
        <p className={classes.content}>
          Market making is an essential function of financial markets. It is the
          process of providing liquidity to a financial instrument by quoting
          both a bid (buy) and an ask (sell) price for the asset. The market
          maker earns a profit by buying at the bid price and selling at the ask
          price, pocketing the difference as a spread.
        </p>

        <p className={classes.content}>
          Market makers are typically large banks or financial institutions that
          have the resources to make large transactions and the expertise to
          analyze market trends. They play a crucial role in ensuring that
          financial markets remain efficient, transparent, and liquid. Without
          market makers, investors would struggle to buy and sell financial
          instruments quickly and at fair prices.
        </p>

        <p className={classes.content}>
          One of the benefits of market-making is that it can reduce volatility
          in the market. When there is a sudden increase in demand for a
          financial instrument, a market maker can step in and provide the
          liquidity needed to stabilize prices. Conversely, if there is a sudden
          sell-off, the market maker can absorb some of the selling pressure by
          buying up the assets and holding them until the market stabilizes.
        </p>

        <p className={classes.content}>
          Market makers also help to reduce the bid-ask spread, which is the
          difference between the highest price a buyer is willing to pay and the
          lowest price a seller is willing to accept. The narrower the spread,
          the more efficient the market is considered to be. Market makers work
          to reduce the spread by competing with each other and adjusting their
          bid-ask prices based on market conditions.
        </p>

        <p className={classes.content}>
          Overall, market-making plays a vital role in the smooth operation of
          financial markets. It provides liquidity, reduces volatility, and
          ensures fair pricing for financial instruments.
        </p>

        <p className={classes.content}>
          To benefit from our expertise in market-making solutions,{" "}
          <Link to={"/request-demo"} style={{ textDecoration: "none" }}>
            <span className={classes.underline}>request a demo</span>
          </Link>{" "}
          today.
        </p>
      </div>
    </div>
  );
};

export default Article1Body;
