import React from "react";
import classes from "./hero.module.css";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className={classes.container}>
      <h3 className={classes.heading}>Learn</h3>
      <p className={classes.subheading}>
        Articles from our team to keep you ahead.
      </p>

      <div className={classes.imgContainer}>
        <p className={classes.imgHeading}>The latest article</p>

        <div className={classes.imgContent}>
          <div className={classes.imgSection}>
            <Link to={"/learn/0"} style={{ textDecoration: "none" }}>
              <img src="/images/learn-hero.png" alt="" />
            </Link>
          </div>
          <div className={classes.mainContent}>
            <Link to={"/learn/0"} style={{ textDecoration: "none" }}>
              <h2 className={classes.mainContentHeading}>
                The Importance of Liquidity in Financial Markets
              </h2>
            </Link>
            <Link to={"/learn/0"} style={{ textDecoration: "none" }}>
              <p className={classes.mainContentDescription}>
                Understand why liquidity is essential to trade smoothly and
                efficiently and the consequences of illiquidity for investors.
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
