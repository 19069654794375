import React, { useEffect } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import PageBody from "../components/MarketMarking/PageBody";
import { Helmet } from "react-helmet";

const MarketMaking = () => {
  useEffect(() => {
    document.title = "Market Making | Prime Bridge";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Prime Bridge's market making solutions provide hedge funds with the knowledge and support to navigate the market. Discover how we can help your business today."
        />
      </Helmet>
      <PageBody />
    </WebsiteLayout>
  );
};

export default MarketMaking;
