import React, { useEffect } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import PageBody from "../components/AdditionalServices/PageBody";
import { Helmet } from "react-helmet";

const AdditionalServices = () => {
  useEffect(() => {
    document.title = "Additional Services | Prime Bridge";
  }, []);
  
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Prime Bridge offers a range of additional services to support hedge funds and high net worth individuals. Explore our additional services to find the right fit for your business."
        />
      </Helmet>
      <PageBody />
    </WebsiteLayout>
  );
};

export default AdditionalServices;
