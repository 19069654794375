import React, { useEffect } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Article2Body from "../components/Learn/Article2Body";
import { Helmet } from "react-helmet";

const Article2 = () => {
  useEffect(() => {
    document.title =
      "Setting Up a Regulated Management Company | Prime Bridge Learn";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Learn about setting up a regulated management company and how it can benefit your business. Read our informative article now."
        />
      </Helmet>
      <Article2Body />
    </WebsiteLayout>
  );
};

export default Article2;
