import useIsMobile from '../../hooks/useIsMobile';
import classes from './PrimeContainer.module.css'


const PrimeContainer = ({primeData}) =>{
    const { isMobile } = useIsMobile();
    return(
        <div className={classes.wrapper}>
        <div className="row">
          {primeData.map((item, i) => (<div className="col-md-6" key={i}>
            <div className={`d-flex ${isMobile ? 'align-items-center' : 'flex-column'} gap-md-0 gap-4`}>
            <div className={classes.iconContainer}>{item.image}</div>
            <div className={`d-flex ${isMobile ? 'flex-column' : 'align-items-center'} gap-md-5`}><h5>{item.name}</h5>{item.tag && <div className={classes.tagContainer}>Coming soon!</div>}</div>
            </div>
            <p>{item.desc}</p>
          </div>))}
        </div>
        </div>
    )
}

export default PrimeContainer