import React, { useEffect } from "react";
import Article6Body from "../components/Learn/Article6Body";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import { Helmet } from "react-helmet";

const Article6 = () => {
  useEffect(() => {
    document.title =
      "Discover effective risk management strategies in financial markets and how Prime Bridge can assist you. Read our informative article now.";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Learn how virtual offices can help you expand your business globally and how Prime Bridge can assist you. Read our informative article now."
        />
      </Helmet>
      <Article6Body />
    </WebsiteLayout>
  );
};

export default Article6;
