import React, { useEffect } from "react";
import Article1Body from "../components/Learn/Article1Body";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import { Helmet } from "react-helmet";

const Article1 = () => {
  useEffect(() => {
    document.title = "Discover the Role of Market Making | Prime Bridge Learn";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Explore the role of market making in financial markets and how it can benefit your business. Read our article now."
        />
      </Helmet>
      <Article1Body />
    </WebsiteLayout>
  );
};

export default Article1;
