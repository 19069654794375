import React, { useState } from "react";
import classes from "./advantage-card.module.css";

const AdvantageCard = ({ heading, message, id }) => {
  return (
    <div
      className={`${classes.container} ${
        heading == "Strong Market Knowledge" ? classes.first : ""
      }`}
    >
      <h4 className={classes.heading}>{heading}</h4>
      <p className={classes.message}>{message}</p>
    </div>
  );
};

export default AdvantageCard;
