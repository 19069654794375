import React, { useState } from "react";
import classes from "./compare.module.css";
import useIsMobile from "../../hooks/useIsMobile";
import DropGainDownIcon from "../../icons/DropGainDownIcon";

const accountGains = [
  "Total",
  "2024",
  "2023"
]

const chartFigures = [
  "Q1 23",
  "Q2 23",
  "Q3 23",
  "Q4 23",
  "Q1 24",
  "Q2 24"
]


const chartFigures24 = [
  "2024",
  "Q1",
  "Q2"
]

const chartFigures23 = [
  "2023",
  "Q1",
  "Q2",
  "Q3",
  "Q4"
]



const Compare = ({active, setActive}) => {

  const { isMobile } = useIsMobile();
  const [showDropDown, setShowDropDown] = useState(false);
  const [figureIndex, setFigureIndex] = useState(0)

  const [figureIndex24, setFigureIndex24] = useState(0)

  const [figureIndex23, setFigureIndex23] = useState(0)

  const dropDownElement = (<>
  <div id="selected-chart" className={`d-flex justify-content-center align-items-center gap-1 ${classes.selectedItem}`} onClick={() => {setShowDropDown(!showDropDown)}} role="button">{accountGains[active]}<DropGainDownIcon color={"black"} width={"13"} height={"10"}  dropDown={showDropDown}/></div>
                    {showDropDown && <div className={`d-flex flex-column justify-content-center gap-3 ${classes.selectOption}`}>
                    {accountGains.map((item, i) => (<div role="button" onClick={() => {setActive(i); setShowDropDown(false)}}>{i !== active && item}</div>))}
                    </div>}
  </>)

  return (
    
    <div className={classes.container}>
      {!isMobile && <div className="position-relative d-flex justify-content-end">{dropDownElement}</div>}
      
        
          
      <h3 className={classes.title}>What are we comparing?</h3>
      <div className="d-flex justify-content-between align-items-center ">
      <p className={classes.subtitle}>
        In the charts below, we are comparing the{" "}
        <strong>returns of clients</strong> using{" "}
        <strong>Prime Bridge's liquidity</strong> to the{" "}
        <strong>SP500 index</strong>.
      </p>
      {/* {!isMobile && <p className={classes.returnTitle}>Total Return: 214.19%</p>} */}
      </div>
      
      <div className="d-flex justify-content-between align-items-center">
      <div className={classes.chartHeaders} style={{marginLeft: isMobile && "15px"}}>
        <div className={`${classes.chartHeaderItem}  ${classes.first}`}>
          <div className={`${classes.statCircle}`}></div>
          <p>Prime Bridge's Liquidity</p>
        </div>

        <div className={`${classes.chartHeaderItem}  ${classes.second}`}>
          <div className={`${classes.statCircle} `}></div>
          <p>SP500 Index</p>
        </div>
      </div>

      {isMobile && <div className="position-relative">{dropDownElement}</div>}
      
        </div>


        {/* {active === 0 && <div className={classes.imgContainer}>
        <h2>2022 CHART</h2>
        </div>} */}

      {active === 0 && <div className={classes.imgContainer}>
      {isMobile && <div className="position-relative">
        <img
          className={`${classes.sm} ${classes.image}`}
          src="/images/chart-sm-total.svg"
          alt=""
        />
         <div className={`d-flex ${classes.chartFigures}`}>{chartFigures.map((num, i) => (<div key={i} className={`${classes.figureContainer} ${figureIndex === i && classes.activeFigure}`} onClick={() => {setFigureIndex(i) }}>{num}</div>))}</div>
        </div>}
        <img
          className={`${classes.lg} ${classes.image}`}
          src="/images/total-return-bar-chart-lg.svg"
          alt=""
        />
      </div>}

      {active === 1 && <div className={classes.imgContainer}>
      {isMobile && <div className="position-relative">
          <img
              className={`${classes.sm} ${classes.image}`}
              src="/images/chart-sm-2024.svg"
              alt=""
            />
            <div className={`d-flex ${classes.chartFigures24}`}>{chartFigures24.map((num, i) => (<div key={i} className={`${classes.figureContainer} ${figureIndex24 === i && classes.activeFigure}`} onClick={() => {setFigureIndex24(i) }}>{num}</div>))}</div>
            </div>}
            <img
              className={`${classes.lg} ${classes.image}`}
              src="/images/return-chart-lg-2024.svg"
            alt=""
          />
        </div>}

      {active === 2 && <div className={classes.imgContainer}>
      {isMobile && <div className="position-relative">  
      <img
          className={`${classes.sm} ${classes.image}`}
          src="/images/chart-sm-2023.svg"
          alt=""
        />
        <div className={`d-flex ${classes.chartFigures23}`}>{chartFigures23.map((num, i) => (<div key={i} className={`${classes.figureContainer} ${figureIndex23 === i && classes.activeFigure}`} onClick={() => {setFigureIndex23(i) }}>{num}</div>))}</div>
        </div>}
        <img
          className={`${classes.lg} ${classes.image}`}
          src="/images/total-return-chart-lg-2023.svg"
          alt=""
        />
        </div>}

       

      <div className={`${classes.chartFooter} ${classes.sm} `}>
        <button className={`${classes.statsBtn} ${classes.first}`}>
          PBLI - {active === 0 ? (figureIndex === 0 ? '36.48%' : figureIndex === 1 ? '62.05%' : figureIndex === 2 ? '62.60%' : figureIndex === 3 ? '53.06%' : figureIndex === 4 ? '8.18%' : figureIndex === 5 && '4.18%') : active === 1 ? (figureIndex24 === 0 ? '12.36%' : figureIndex24 === 1 ? '8.18%' : figureIndex24 === 2 && '4.18%') : active === 2 && (figureIndex23 === 0 ? '214.19%' : figureIndex23 === 1 ? '36.48%' : figureIndex23 === 2 ? '62.05%' : figureIndex23 === 3 ? '62.60%' : figureIndex23 === 4 && '53.06%') }
        </button>
        <button className={`${classes.statsBtn} ${classes.second}`}>
          S&P500 - {active === 0 ? (figureIndex === 0 ? '7.08%' : figureIndex === 1 ? '8.18%' : figureIndex === 2 ? '-3.53%' : figureIndex === 3 ? '11.14%' : figureIndex === 4 ? '9.86%' : figureIndex === 5 && '4.11%') : active === 1 ? (figureIndex24 === 0 ? '13.97%' : figureIndex24 === 1 ? '9.86%' : figureIndex24 === 2 && '4.11%') : active === 2 &&  (figureIndex23 === 0 ? '22.87%%' : figureIndex23 === 1 ? '7.08%' : figureIndex23 === 2 ? '8.18%' : figureIndex23 === 3 ? '-3.53%' : figureIndex23 === 4 && '11.14%')}
        </button>
      </div>
    </div>
  );
};

export default Compare;
