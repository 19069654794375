import React, { useEffect } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Article5Body from "../components/Learn/Article5Body";
import { Helmet } from "react-helmet";

const Article6 = () => {
  useEffect(() => {
    document.title = "Beginner's Guide to Trading | Prime Bridge Learn";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Learn the basics of trading in financial markets with our informative article. Start your journey with Prime Bridge today."
        />
      </Helmet>
      <Article5Body />
    </WebsiteLayout>
  );
};

export default Article6;
