import React, { useEffect } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Article0Body from "../components/Learn/Article0Body";
import { Helmet } from "react-helmet";

const Article0 = () => {
  useEffect(() => {
    document.title =
      "Understanding the Importance of Liquidity | Prime Bridge Learn";
  }, []);
  
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Learn more about the importance of liquidity in financial markets and how Prime Bridge can help you navigate them. Read our article now."
        />
      </Helmet>
      <Article0Body />
    </WebsiteLayout>
  );
};

export default Article0;
