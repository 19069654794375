import React from "react";
import classes from "./article.module.css";
import { Link } from "react-router-dom";

const ArticleCard = ({ title, imgUrl, description, link }) => {
  return (
    <div className={classes.card}>
      <Link
        to={link}
        style={{ textDecoration: "none", display: "block", color: "black" }}
      >
        <>
          <img className={classes.img} src={imgUrl} alt="" />
          <div className={classes.cardContent}>
            <h2 className={classes.title}>{title}</h2>

            <p className={classes.description}>{description}</p>
          </div>
        </>
      </Link>
    </div>
  );
};

export default ArticleCard;
