import React from "react";
import classes from "./hero.module.css";

const Hero = () => {
  return (
    <div className={classes.container}>
      <h3 className={classes.heading}>Prime Bridge Services</h3>
      <p className={`${classes.lg} ${classes.info}`}>
        Your trusted partner in providing financial solutions to help you
        succeed.
      </p>
    </div>
  );
};

export default Hero;
