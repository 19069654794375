import React from "react";
import classes from "./article.module.css";
import ArticleCard from "./ArticleCard";
import { Link } from "react-router-dom";

const articles = [
  {
    link: "/learn/1",
    imgUrl: "/images/article-1.png",
    title: "Market Making and its Role in Financial Markets",
    description:
      "How market makers provide liquidity to the market, reduce volatility, and help to ensure fair pricing.",
  },
  {
    link: "/learn/2",
    imgUrl: "/images/article-2.png",
    title: "What is a Regulated Management Company and How to Set One Up",
    description:
      "Discover how to a legal structure, register with financial regulatory authorities, and accept investments from clients.",
  },
  {
    link: "/learn/3",
    imgUrl: "/images/article-3.png",
    title: "The Benefits of Offshore Corporation Formation",
    description:
      "Learn more about this legal strategy that allows businesses to establish a corporate entity in a foreign country.",
  },
  {
    link: "/learn/4",
    imgUrl: "/images/article-4.png",
    title: "How Virtual Offices Can Help You Expand Your Business Globally",
    description:
      "Explore the concept of virtual offices and how they can help businesses expand globally.",
  },
  {
    link: "/learn/5",
    imgUrl: "/images/article-5.png",
    title: "A Beginner's Guide to Trading in Financial Markets",
    description:
      "The basics of financial instruments, trading strategies, and risk management to succeed with your investments.",
  },
  {
    link: "/learn/6",
    imgUrl: "/images/article-6.png",
    title: "Tips for Effective Risk Management in Financial Markets",
    description:
      "Diversification, stop-loss orders, and position sizing to help investors manage risk and protect their investments.",
  },
];

const Articles = () => {
  return (
    <div className={classes.container}>
      <h3 className={classes.heading}>More articles</h3>

      <div className={classes.cardContainer}>
        {articles.map((article, index) => (
          // <Link
          //   style={{ textDecoration: "none", display: "block", color: "black" }}
          //   to={article.link}
          // >
          <ArticleCard
            key={index}
            title={article.title}
            imgUrl={article.imgUrl}
            description={article.description}
            link={article.link}
          />
          // </Link>
        ))}
      </div>
    </div>
  );
};

export default Articles;
