import classes from './MainWithdrawalFees.module.css'

const MainWithdrawalFees = () => {
  return (
    <div className={classes.container}>
        <h3>Withdrawal Fees</h3>
      <p>
        When it comes to managing your investments, it's crucial to understand
        the fees associated with any financial service you deal with.
      </p>
      <p>
        At Prime Bridge, we want to offer a clear breakdown of its withdrawal
        fees to ensure transparency and help you make informed decisions about
        your funds.
      </p>
      <p>
        Here's a closer look at the components that make up Prime Bridge's
        withdrawal fees:
      </p>
      <p>
        <strong>Total Withdrawal:</strong> This refers to the amount you request
        to withdraw from your Prime Bridge account. It represents the total sum
        you want to access for personal use, reinvestment, or any other purpose.
      </p>
      <p>
        <strong>PB Share:</strong> Prime Bridge, like many financial servicess,
        takes a portion of the withdrawal to cover its operational costs and
        services. This is the fraction of the total withdrawal that Prime Bridge
        retains, and it is a standard practice in the industry.
      </p>
      <p>
        <strong>Withdrawal Fee:</strong> In addition to Prime Bridge's share,
        there's a withdrawal fee that goes to the regulated custodian. This fee
        covers the administrative and regulatory costs associated with
        processing the withdrawal. While this may seem like an additional
        deduction, it's essential for maintaining a secure and compliant
        trading environment.
      </p>

      <p>
        <strong>Net Payout:</strong> This is the final amount you receive after
        all the deductions mentioned above. It's the actual cash you'll have
        access to after both Prime Bridge's share and the withdrawal fee have
        been subtracted from the total withdrawal.
      </p>
      <p>
        Prime Bridge's commitment to transparency regarding withdrawal fees
        empowers you to evaluate the potential returns and weigh them against
        the associated costs. Before making any withdrawals, you will be able to
        see the withdrawal details and the cost of each fee mentioned above.
      </p>
      <p>
        If you have any questions, don't hesitate to reach out to us <a href="mailto:accounts@primebridge.io">via email</a>.
      </p>
    </div>
  );
};

export default MainWithdrawalFees;
