import React from "react";
import Request from "../../assets/svgs/request.svg";
import classes from "./info.module.css";

const InfoSection = () => {
  return (
    <div className={classes.container}>
      <h2 className={classes.heading}>
        Experience Prime Market Making and Liquidity Solutions
      </h2>

      <div className={classes.imgContainer}>
        <img src={Request} />
      </div>
    </div>
  );
};

export default InfoSection;
