import React from "react";
import classes from "../Privacy/pagebody.module.css";

const PageBody = () => {
  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <p className={classes.heading}>
          <span className={classes.sm}>Trading Disclosure</span>
          <span className={classes.lg}>Prime Bridge Trading Disclosure</span>
        </p>
      </div>

      <div className={classes.contentContainer}>
        <p className={classes.text}>
          Prime Bridge provides market-making and liquidity solutions for a
          variety of financial instruments, including but not limited to foreign
          exchange (FX), precious metals, indices, and cryptocurrencies. Our
          platform allows clients to trade these instruments to maximize returns
          and manage risk.
        </p>

        <p className={`${classes.text} ${classes.last}`}>
          It is important to note that trading in these instruments carries a
          high level of risk and may only be suitable for some investors. The
          prices of FX, precious metals, indices, and cryptocurrencies can
          fluctuate rapidly and unpredictably, and clients may experience
          significant losses due to their trading activities.
        </p>
        <p className={`${classes.text} ${classes.last}`}>
          Prime Bridge is not responsible for any losses that may occur as a
          result of clients' trading activities. Our platform is designed to
          provide market-making and liquidity solutions, and we do not guarantee
          the performance of any particular trade or investment. Clients are
          solely responsible for making their investment decisions and should
          consider their risk tolerance and investment objectives before
          trading.
        </p>

        <p className={`${classes.text} ${classes.last}`}>
          In providing our services, Prime Bridge uses several counterparties to
          execute trades on behalf of our clients. These counterparties may
          include banks, trading venues, and other financial institutions. Prime
          Bridge does not control the operations of these counterparties and is
          not responsible for any losses that may occur due to their actions.{" "}
        </p>
        
        <p className={`${classes.text} ${classes.last}`}> While Prime Bridge owns trading data flow, we recognize the importance of data access for legitimate purposes. Accordingly: 
        <ol type="a">
        <li> Authorized Access: Access to trading data flow will be provided to authorized personnel and entities, including regulatory bodies, as required by law or industry regulations.</li> 
          <li> Data Sharing: We may share trading data with authorized third-party service providers, partners, or vendors engaged in supporting our trading operations. Such sharing will be subject to strict contractual agreements and data protection measures.</li> 
          <li> Data Analytics: We reserve the right to analyze trading data for research, risk management, and business optimization purposes, while ensuring that individual traders' identities are not disclosed.</li> 
          </ol>
        </p>

        <p className={`${classes.text} ${classes.last}`}>
          Please review risk disclosures and terms of use before trading. Don't
          hesitate to contact our support team if you have any questions or
          concerns about the risks associated with trading in FX,precious
          metals, indices, and cryptocurrencies, or the counterparties we use to
          execute trades.{" "}
        </p>
        <p className={`${classes.text} ${classes.last}`}>
          Using Prime Bridge's platform, clients acknowledge and agree to these
          disclosures and assume the risks associated with trading in these
          instruments.{" "}
        </p>
      </div>
    </div>
  );
};

export default PageBody;
