import React from "react";
import classes from "./styles/footer.module.css";
import Logo from "../../assets/svgs/LogoHalf.svg";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <div className={classes.footerLogoContainer}>
            <img src={Logo} className={classes.footerLogo} />
            <div className={classes.mobileSocialIcon}>
              <a
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                href={"https://www.linkedin.com/company/prime-bridge/"}
                target="_blank"
              >
                <p className={classes.linkedin}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.55696e-07 2.40304C3.55696e-07 1.76571 0.253177 1.15449 0.703833 0.703836C1.15449 0.253179 1.76571 3.20359e-06 2.40304 3.20359e-06H26.3576C26.6734 -0.000512641 26.9862 0.0612727 27.2782 0.181822C27.5701 0.302371 27.8354 0.479316 28.0588 0.702527C28.2823 0.925738 28.4595 1.19083 28.5804 1.48263C28.7013 1.77443 28.7634 2.0872 28.7632 2.40304V26.3576C28.7636 26.6735 28.7016 26.9863 28.5809 27.2783C28.4602 27.5702 28.2832 27.8355 28.0599 28.0589C27.8365 28.2824 27.5714 28.4596 27.2795 28.5804C26.9876 28.7013 26.6748 28.7634 26.3589 28.7632H2.40304C2.08736 28.7632 1.77477 28.701 1.48313 28.5802C1.1915 28.4593 0.926531 28.2822 0.703371 28.0589C0.480211 27.8356 0.303232 27.5706 0.182545 27.2789C0.0618583 26.9872 -0.000171397 26.6746 3.55696e-07 26.3589V2.40304ZM11.385 10.9666H15.2798V12.9225C15.842 11.7982 17.2802 10.7862 19.4413 10.7862C23.5845 10.7862 24.5664 13.0258 24.5664 17.135V24.7468H20.3735V18.0711C20.3735 15.7309 19.8113 14.4104 18.3836 14.4104C16.4029 14.4104 15.5792 15.8342 15.5792 18.0711V24.7468H11.385V10.9666ZM4.1942 24.5677H8.3884V10.7862H4.1942V24.5664V24.5677ZM8.98851 6.2913C8.99641 6.65042 8.93251 7.00749 8.80055 7.34157C8.66859 7.67565 8.47123 7.98001 8.22005 8.23678C7.96887 8.49356 7.66893 8.69757 7.33783 8.83686C7.00674 8.97614 6.65115 9.04789 6.29195 9.04789C5.93276 9.04789 5.57717 8.97614 5.24608 8.83686C4.91498 8.69757 4.61504 8.49356 4.36386 8.23678C4.11268 7.98001 3.91532 7.67565 3.78336 7.34157C3.6514 7.00749 3.58749 6.65042 3.5954 6.2913C3.61092 5.58641 3.90184 4.91563 4.40585 4.42259C4.90986 3.92956 5.58689 3.65348 6.29195 3.65348C6.99701 3.65348 7.67405 3.92956 8.17806 4.42259C8.68207 4.91563 8.97298 5.58641 8.98851 6.2913Z"
                      fill="white"
                    />
                  </svg>
                </p>
              </a>

              <a
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                href={"https://instagram.com/prime.bridge?igshid=YmMyMTA2M2Y="}
                target="_blank"
              >
                <p className={classes.linkedin}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-instagram"
                  >
                    <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                  </svg>
                </p>
              </a>
            </div>
          </div>
          <div className={classes.linkContainer}>
            <div className={classes.linkSubContainer}>
              <h2 className={classes.textLogo}>Prime Bridge</h2>

              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/services"}
              >
                <p className={classes.linkItem}>Services</p>
              </NavLink>
              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/about"}
              >
                <p className={classes.linkItem}>About Us</p>
              </NavLink>

              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/learn"}
              >
                <p className={classes.linkItem}>Learn</p>
              </NavLink>
            </div>
            <div className={classes.linkSubContainer}>
              <h2 className={classes.textLogo}>Policies</h2>
              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/privacy"}
              >
                <p className={classes.linkItem}>Privacy</p>
              </NavLink>

              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/terms-of-use"}
              >
                <p className={classes.linkItem}>Terms of Use</p>
              </NavLink>

              <NavLink
                className={classes.linkItem}
                style={{ textDecoration: "none" }}
                to={"/disclosure"}
              >
                <p className={classes.linkItem}>Disclosure</p>
              </NavLink>
            </div>
          </div>
        </div>
        <div className={classes.actionsContainer}>
          <p
            style={{ fontWeight: 600 }}
            className={`${classes.linkItem} ${classes.spacer}`}
          >
            Client Login
          </p>
          <NavLink
            className={`${classes.linkItem} ${classes.spacer}`}
            style={{ textDecoration: "none" }}
            to={"/request-demo"}
          >
            <button className={classes.demoBtn}>Request a Demo</button>
          </NavLink>

          <div className={classes.socialIcons}>
            <a
              className={classes.linkItem}
              style={{ textDecoration: "none" }}
              href={"https://www.linkedin.com/company/prime-bridge/"}
              target="_blank"
            >
              <p className={classes.linkedin}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.55696e-07 2.40304C3.55696e-07 1.76571 0.253177 1.15449 0.703833 0.703836C1.15449 0.253179 1.76571 3.20359e-06 2.40304 3.20359e-06H26.3576C26.6734 -0.000512641 26.9862 0.0612727 27.2782 0.181822C27.5701 0.302371 27.8354 0.479316 28.0588 0.702527C28.2823 0.925738 28.4595 1.19083 28.5804 1.48263C28.7013 1.77443 28.7634 2.0872 28.7632 2.40304V26.3576C28.7636 26.6735 28.7016 26.9863 28.5809 27.2783C28.4602 27.5702 28.2832 27.8355 28.0599 28.0589C27.8365 28.2824 27.5714 28.4596 27.2795 28.5804C26.9876 28.7013 26.6748 28.7634 26.3589 28.7632H2.40304C2.08736 28.7632 1.77477 28.701 1.48313 28.5802C1.1915 28.4593 0.926531 28.2822 0.703371 28.0589C0.480211 27.8356 0.303232 27.5706 0.182545 27.2789C0.0618583 26.9872 -0.000171397 26.6746 3.55696e-07 26.3589V2.40304ZM11.385 10.9666H15.2798V12.9225C15.842 11.7982 17.2802 10.7862 19.4413 10.7862C23.5845 10.7862 24.5664 13.0258 24.5664 17.135V24.7468H20.3735V18.0711C20.3735 15.7309 19.8113 14.4104 18.3836 14.4104C16.4029 14.4104 15.5792 15.8342 15.5792 18.0711V24.7468H11.385V10.9666ZM4.1942 24.5677H8.3884V10.7862H4.1942V24.5664V24.5677ZM8.98851 6.2913C8.99641 6.65042 8.93251 7.00749 8.80055 7.34157C8.66859 7.67565 8.47123 7.98001 8.22005 8.23678C7.96887 8.49356 7.66893 8.69757 7.33783 8.83686C7.00674 8.97614 6.65115 9.04789 6.29195 9.04789C5.93276 9.04789 5.57717 8.97614 5.24608 8.83686C4.91498 8.69757 4.61504 8.49356 4.36386 8.23678C4.11268 7.98001 3.91532 7.67565 3.78336 7.34157C3.6514 7.00749 3.58749 6.65042 3.5954 6.2913C3.61092 5.58641 3.90184 4.91563 4.40585 4.42259C4.90986 3.92956 5.58689 3.65348 6.29195 3.65348C6.99701 3.65348 7.67405 3.92956 8.17806 4.42259C8.68207 4.91563 8.97298 5.58641 8.98851 6.2913Z"
                    fill="white"
                  />
                </svg>
              </p>
            </a>

            <a
              className={classes.linkItem}
              style={{ textDecoration: "none" }}
              href={"https://www.instagram.com/prime.bridge/"}
              target="_blank"
            >
              <p className={classes.linkedin}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-instagram"
                >
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                </svg>
              </p>
            </a>
          </div>
        </div>
      </div>

      <div className={classes.copyRightContainer}>
        <p className={classes.copyRight}>
          Copyright © 2023, Prime Bridge Securities LLC. All Rights Reserved.
        </p>

        <p className={classes.copyRight}>
          One Raffles Place Office Tower 1 Singapore{" "}
          <span style={{ textDecoration: "underline" }}>048616 40-02</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
