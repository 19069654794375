import React from "react";
import classes from "./article-1.module.css";
import { Link } from "react-router-dom";

const Article2Body = () => {
  return (
    <div className={classes.container}>
      <div className={classes.hero}>
        <div className={classes.content}>
          <Link to={"/learn"} style={{ textDecoration: "none" }}>
            <p className={classes.sectionTitle}>Learn</p>
          </Link>

          <h2 className={classes.heading}>
            What is a Regulated Management Company and How to Set One Up
          </h2>

          <p className={classes.subTitle}>
            Steps involved in setting up an RMC, including choosing a legal
            structure, registering with financial regulatory authorities, and
            accepting investments from clients.
          </p>
        </div>

        {/* <div className={classes.image}>
          <img src="/images/article-2.png" />
        </div> */}
      </div>

      <div className={classes.contentSection}>
        <p className={classes.content}>
          A regulated management company (RMC) is a type of investment fund that
          is authorized and regulated by a financial regulatory authority. RMCs
          can invest in a range of assets, including stocks, bonds, and real
          estate, on behalf of their clients.
        </p>

        <p className={classes.content}>
          The main advantage of setting up an RMC is that it allows investors to
          pool their money together and access a diverse range of assets that
          they may not be able to invest in individually. Additionally, RMCs are
          typically managed by professionals who have a wealth of knowledge and
          experience in the investment industry.
        </p>

        <p className={classes.content}>
          To set up an RMC, there are several steps that need to be taken. The
          first step is to choose the appropriate legal structure for the RMC.
          This will depend on factors such as the type of assets to be invested
          in, the size of the fund, and the number of investors. Common legal
          structures for RMCs include corporations, limited liability companies
          (LLCs), and partnerships.
        </p>

        <p className={classes.content}>
          Once the legal structure has been decided, the next step is to
          register the RMC with the relevant financial regulatory authority.
          This typically involves submitting a detailed application that
          outlines the investment strategy, management team, and other key
          details about the fund.
        </p>

        <p className={classes.content}>
          After the RMC has been registered, it can begin accepting investments
          from clients. The RMC will typically charge a management fee and may
          also charge performance fees if the fund performs well.
        </p>

        <p className={classes.content}>
          In conclusion, setting up an RMC can be a great way to provide
          investors with access to a diverse range of assets and generate income
          through management fees. However, it is important to carefully
          consider the legal and regulatory requirements before starting an RMC
          to ensure compliance with local laws and regulations.
        </p>

        <p className={classes.content}>
          We have a solid knowledge of these matters to help you meet regulatory
          requirements and establish a compliant business structure.{" "}
          <Link to={"/request-demo"}>
            <span className={classes.underline}>Request a demo today</span>
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default Article2Body;
