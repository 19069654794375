import React from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import PageBody from "../components/Privacy/PageBody";

const Privacy = () => {
  return (
    <WebsiteLayout>
      <PageBody />
    </WebsiteLayout>
  );
};

export default Privacy;
