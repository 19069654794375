import React, { useEffect } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Article4Body from "../components/Learn/Article4Body";
import { Helmet } from "react-helmet";

const Article4 = () => {
  useEffect(() => {
    document.title =
      "Expanding Your Business with Virtual Offices | Prime Bridge Learn";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Learn how virtual offices can help you expand your business globally and how Prime Bridge can assist you. Read our informative article now."
        />
      </Helmet>
      <Article4Body />
    </WebsiteLayout>
  );
};

export default Article4;
