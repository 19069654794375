import React, { useRef, useState, useEffect } from "react";
import AdvantageCard from "./AdvantageCard";
import classes from "./advantage.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const advantages = [
  {
    id: "0",
    title: "Strong Market Knowledge",
    message:
      "With extensive experience and deep insights into market trends and behavior, Prime Bridge is well-positioned to provide its clients with the best possible market intelligence and insights.",
  },
  {
    id: "1",
    title: "Innovative Technologies",
    message:
      "Prime Bridge leverages cutting-edge technology to provide its clients with the latest in market making and liquidity solutions. This includes advanced algorithms, predictive analytics, and robust trading systems.",
  },
  {
    id: "2",
    title: "Dedicated Support",
    message:
      "Prime Bridge is dedicated to providing its clients with top-notch customer service and support. Our team of experienced professionals is always available to help clients navigate the markets and make informed trading decisions.",
  },
];

const Advantages = () => {
  const [sliderIndex, setSliderIndex] = useState("0");
  const sliderRef = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current?.slickGoTo(parseInt(sliderIndex));
    }
  }, [sliderIndex]);
  return (
    <div className={classes.container}>
      <p className={`${classes.heading} ${classes.lg}`}>
        Why choose Prime Bridge?
      </p>
      <p className={`${classes.heading} ${classes.sm}`}>Why Prime Bridge?</p>

      <div className={classes.sliderContainer}>
        <Slider
          afterChange={(index) => setSliderIndex(index)}
          ref={sliderRef}
          {...settings}
        >
          {advantages?.map((importance, index) => (
            <AdvantageCard
              key={index}
              message={importance.message}
              heading={importance.title}
              id={importance.id}
            />
          ))}
        </Slider>
        <div className={classes.sliderInputContainer}>
          <input
            type="range"
            min="0"
            max="2"
            value={sliderIndex}
            onChange={(e) => setSliderIndex(e.target.value)}
            className={classes.slider}
            id="myRange"
          />
        </div>
      </div>
      <div className={classes.importanceList}>
        {advantages?.map((importance, index) => (
          <AdvantageCard
            key={index}
            message={importance.message}
            heading={importance.title}
          />
        ))}
      </div>
    </div>
  );
};

export default Advantages;
