import React from "react";
import classes from "../Privacy/pagebody.module.css";

const PageBody = () => {
  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <p className={classes.heading}>
          <span className={classes.lg}>Prime Bridge's Terms of Use</span>
          <span className={classes.sm}>Terms of Use</span>
        </p>
      </div>

      <div className={classes.contentContainer}>
        <p className={classes.text}>
          Welcome to Prime Bridge's platform for market-making and liquidity
          solutions. By accessing or using our platform, you agree to be bound
          by the following terms and conditions of use (the "Terms"). Please do
          not use our platform if you do not agree to these Terms.
        </p>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>1.</span> Service Description
          </p>
          <p className={classes.text}>
            Prime Bridge provides a platform for market-making and liquidity
            solutions. We offer our services to clients interested in maximizing
            their returns and managing risk in the financial markets.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>2.</span> Account Eligibility
          </p>
          <p className={classes.text}>
            To use our platform, you must be at least 18 years of age and have
            the legal capacity to enter into a binding agreement. Using our
            platform, you represent and warrant that you meet these eligibility
            requirements.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>3.</span> User Conduct
          </p>
          <p className={classes.text}>
            When using our platform, you agree to comply with all applicable
            laws and regulations and to not engage in any illegal or harmful
            activities, including but not limited to the transmission of viruses
            or other harmful code or the use of our platform for any
            unauthorized or malicious purposes.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>4.</span> Intellectual Property
          </p>
          <p className={classes.text}>
            text, graphics, logos, icons, images, and software, are protected by
            intellectual property laws and are owned or licensed by Prime
            Bridge. Without our prior written consent, you may not use any
            content or materials on our platform for any commercial purposes.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            <span className={classes.smallText}>5.</span> Limitation of
            Liability
          </p>

          <p className={classes.text}>
            Prime Bridge shall not be liable for any damages arising from or in
            connection with the use of our platform. This includes, but is not
            limited to, any indirect, incidental, special, or consequential
            damages, even if Prime Bridge has been advised of the possibility of
            such damages.
          </p>
        </div>
        <div className={classes.card}>
          <p className={classes.cardHeading}>
            {" "}
            <span className={classes.smallText}>6.</span> Changes to Terms
          </p>

          <p className={classes.text}>
            Prime Bridge reserves the right to modify these Terms at any time,
            and your continued use of our platform after any changes have been
            made constitutes your acceptance of the revised Terms.
          </p>
        </div>

        <div className={classes.card}>
          <p className={classes.cardHeading}>
            {" "}
            <span className={classes.smallText}>7.</span> Termination
          </p>
          <p className={classes.text}>
            Prime Bridge may terminate or suspend your use of our platform at
            any time, with or without cause, with or without notice.
          </p>
        </div>

        <p className={`${classes.text} ${classes.last}`}>
          These Terms constitute the entire agreement between you and Prime
          Bridge concerning the use of our platform and supersede all prior
          agreements and understandings, whether written or oral, between the
          parties.
        </p>
        <p className={`${classes.text} ${classes.last}`}>
          By using our platform, you agree to be bound by these Terms. Don't
          hesitate to contact our support team if you have any questions or
          concerns regarding these Terms.{" "}
        </p>
      </div>
    </div>
  );
};

export default PageBody;
