import React, { useEffect } from "react";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Article3Body from "../components/Learn/Article3Body";
import { Helmet } from "react-helmet";

const Article3 = () => {
  useEffect(() => {
    document.title =
      "Explore the Benefits of Offshore Formation | Prime Bridge Learn";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Discover the benefits of offshore corporation formation and how it can benefit your business. Read our informative article now."
        />
      </Helmet>
      <Article3Body />
    </WebsiteLayout>
  );
};

export default Article3;
