import React, { useEffect } from "react";
import Empowering from "../components/About/Empowering";
import Hero from "../components/About/Hero";
import Importance from "../components/About/Importance";
import WebsiteLayout from "../components/Layouts/WebsiteLayout";
import Stats from "../components/Service/Stats";
import Works from "../components/Home/Works";
import MeetFounder from "../components/About/MeetFounder";
import { Helmet } from "react-helmet";

const About = () => {
  useEffect(() => {
    document.title = "About Prime Bridge | Our Founder & Importance";
  }, []);
  return (
    <WebsiteLayout>
      <Helmet>
        <meta
          name="description"
          content="Discover more about Prime Bridge, our founder, and our importance in the evolving market. Learn how we can help your business today."
        />
      </Helmet>
      <Hero />
      <Stats isHome />
      <MeetFounder />
      <Importance />
      <Works />
    </WebsiteLayout>
  );
};

export default About;
