import React from "react";
import classes from "./stats.module.css";

const Stats = ({ isHome }) => {
  return (
    <div className={`${classes.statMainContainer} ${isHome && classes.isHome}`}>
      <p className={`${classes.heading} ${isHome && classes.isHome}`}>
        Why choose Prime Bridge?
      </p>
      <div className={classes.statContainer}>
        <div className={`${classes.statItem} ${classes.first}`}>
          <div className={`${classes.stat} `}>
            <p>5+</p>
          </div>
          <div className={`${classes.info} ${classes.first}`}>
            <p>markets we operate in</p>
          </div>
        </div>
        <div className={`${classes.statItem} ${classes.statMiddleItem1}`}>
          <div className={classes.stat}>
            <p>150M+</p>
          </div>
          <div className={classes.info}>
            <p>
              in liquidity provided to hedge funds, UHNW individuals, and
              partners{" "}
            </p>
          </div>
        </div>
        <div className={`${classes.statItem} ${classes.statMiddleItem2}`}>
          <div className={classes.stat}>
            <p>65+</p>
          </div>
          <div className={classes.info}>
            <p>clients</p>
          </div>
        </div>
        <div className={`${classes.statItem} ${classes.custom} `}>
          <div className={`${classes.stat} ${classes.custom} ${classes.last}`}>
            <p>7B+</p>
          </div>
          <div className={`${classes.info} ${classes.custom} ${classes.last}`}>
            <p>
              dollars in trades executed monthly (approximate notional value,
              excluding swaps)
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
