import React, { useEffect } from "react";
import classes from "../components/page_styles/request-demo.module.css";
import PageBody from "../components/RequestDemo/PageBody";
import RequestNavBar from "../components/RequestDemo/RequestNavBar";
import Footer from "../components/shared/Footer";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const RequestDemo = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    document.title =
      "Request a Demo and Discover Innovative Liquidity Solutions | Prime Bridge";
  }, []);
  return (
    <div className={classes.page}>
      <Helmet>
        <meta
          name="description"
          content="Request a demo today to discover innovative liquidity solutions designed for hedge funds, high net worth individuals, and family firms."
        />
      </Helmet>
      <RequestNavBar />
      <PageBody />
      <div className={classes._footer}>
        <Footer />
      </div>
    </div>
  );
};

export default RequestDemo;
