import React, { useEffect, useState } from "react";
import classes from "./styles/navbar.module.css";
import LogoSVG from "../../assets/svgs/LogoWhite.svg";
import ToggleSVG from "../../assets/svgs/Toggle.svg";
import { Link, NavLink } from "react-router-dom";
import SideNavBar from "./SideNavBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { env } from "../../constants/env";

// const popover = (
//   <Popover style={{ background: "transparent" }} id="popover-basic">
//     <Popover.Body className={classes.popover}>
//       <div>
//         <div className={classes.allServices}>
//           <Link to={"/services"} style={{ textDecoration: "none" }}>
//             <p>See all Services</p>
//           </Link>
//         </div>
//         <div className={classes.subContainer}>
//           <h2 className={classes.title}>Main</h2>

//           <div className={classes.linkContainer}>
//             <p
//               className={` ${currentLink("liquidity") && classes.underlined} ${
//                 classes.link
//               }`}
//             >
//               <Link
//                 to={"/services/liquidity"}
//                 style={{ textDecoration: "none", color: "white" }}
//               >
//                 Liquidity Solutions
//               </Link>
//             </p>

//             <p
//               className={` ${
//                 currentLink("market-making") && classes.underlined
//               } ${classes.link}`}
//             >
//               <Link
//                 to={"/services/market-making"}
//                 style={{ textDecoration: "none", color: "white" }}
//               >
//                 Market Making
//               </Link>
//             </p>
//           </div>
//         </div>

//         <div style={{ marginTop: "2rem" }} className={classes.subContainer}>
//           <h2 className={classes.title}>Additional</h2>

//           <div className={classes.linkContainer}>
//             <p className={classes.link}>
//               <Link
//                 to={"/services/market-making"}
//                 style={{ textDecoration: "none", color: "white" }}
//               >
//                 Regulated Management Company Formation
//               </Link>
//             </p>

//             <p className={classes.link}>
//               <Link
//                 to={"/services/market-making"}
//                 style={{ textDecoration: "none", color: "white" }}
//               >
//                 Bank Account Formation
//               </Link>
//             </p>
//             <p className={classes.link}>
//               <Link
//                 to={"/services/market-making"}
//                 style={{ textDecoration: "none", color: "white" }}
//               >
//                 Offshore Corp Formation
//               </Link>
//             </p>
//             <p className={classes.link}>
//               <Link
//                 to={"/services/market-making"}
//                 style={{ textDecoration: "none", color: "white" }}
//               >
//                 Virtual Office Set Up
//               </Link>
//             </p>
//           </div>
//         </div>
//       </div>
//     </Popover.Body>
//   </Popover>
// );

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [window.location.hash]);

  const currentLink = (path) => {
    if (window.location.pathname.split("/")[1] == path) return true;
    else return false;
  };

  const currentSubLink = (path) => {
    if (window.location.pathname.split("/")[2] == path) return true;
    else return false;
  };

  return (
    <section className={classes.navbar}>
      <div className={classes.logo}>
        <NavLink
          className={classes.linkItem}
          style={{ textDecoration: "none" }}
          to={"/"}
          a
        >
          <img className={classes.logoContainer} src={LogoSVG} />
        </NavLink>
      </div>
      <div className={classes.navLink}>
        <div className={classes.middleMenuLinks}>
          <NavLink
            className={classes.linkItem}
            style={({ isActive }) => ({
              // textDecoration: isActive ? "underline" : "none",
              // color: isActive ? "#0077B5 " : "white",
            })}
            // to={"/services"}
          >
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={
                <Popover
                  style={{ background: "transparent" }}
                  id="popover-basic"
                >
                  <Popover.Body className={classes.popover}>
                    <div>
                      <div className={classes.allServices}>
                        <Link
                          to={"/services"}
                          style={{ textDecoration: "none" }}
                        >
                          <p>See all Services</p>
                        </Link>
                      </div>
                      <div className={classes.subContainer}>
                        <h2 className={classes.title}>Main</h2>

                        <div className={classes.linkContainer}>
                          <p
                            className={` ${
                              currentSubLink("liquidity") && classes.underlined
                            } ${classes.link}`}
                          >
                            <Link
                              to={"/services/liquidity"}
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              Liquidity Solutions
                            </Link>
                          </p>

                          <p
                            className={` ${
                              currentSubLink("market-making") &&
                              classes.underlined
                            } ${classes.link}`}
                          >
                            <Link
                              to={"/services/market-making"}
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              Market Making
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div
                        style={{ marginTop: "2rem" }}
                        className={classes.subContainer}
                      >
                        <h2 className={classes.title}>Additional</h2>

                        <div className={classes.linkContainer}>
                          <p className={classes.link}>
                            <Link
                              to={"/services/additional-services#1"}
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              Regulated Management Company Formation
                            </Link>
                          </p>

                          <p className={classes.link}>
                            <Link
                              to={"/services/additional-services#2"}
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              Bank Account Formation
                            </Link>
                          </p>
                          <p className={classes.link}>
                            <Link
                              to={"/services/additional-services#3"}
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              Offshore Corp Formation
                            </Link>
                          </p>
                          <p className={classes.link}>
                            <Link
                              to={"/services/additional-services#4"}
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              Virtual Office Set Up
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Popover.Body>
                </Popover>
              }
              rootClose
            >
              <p className={`${currentLink("services") && classes.links}`}>
                Services
              </p>
            </OverlayTrigger>
          </NavLink>
          <NavLink
            className={classes.linkItem}
            to={"/performance"}
            style={({ isActive }) => ({
              // textDecoration: isActive ? "underline" : "none",
              // color: isActive ? "#0077B5" : "white",
            })}
          >
            <p className={`${currentLink("performance") && classes.links}`}>
              Performance
            </p>
          </NavLink>
          <NavLink
            className={classes.linkItem}
            to={"/about"}
            style={({ isActive }) => ({
              // textDecoration: isActive ? "underline" : "none",
              // color: isActive ? "#0077B5" : "white",
            })}
          >
            <p className={`${currentLink("about") && classes.links}`}>
              About Us
            </p>
          </NavLink>

          <NavLink
            className={classes.linkItem}
            to={"/partners"}
            style={({ isActive }) => ({
              // textDecoration: isActive ? "underline" : "none",
              // color: isActive ? "#0077B5" : "white",
            })}
          >
            <p className={`${currentLink("partners") && classes.links}`}>
              Partners
            </p>
          </NavLink>

          <NavLink
            className={classes.linkItem}
            to={"/news"}
            style={({ isActive }) => ({
              // textDecoration: isActive ? "underline" : "none",
              // color: isActive ? "#0077B5" : "white",
            })}
          >
            <p className={`${currentLink("news") && classes.links}`}>
              News
            </p>
          </NavLink>

          <NavLink
            className={classes.linkItem}
            to={"/learn"}
            style={({ isActive }) => ({
              // textDecoration: isActive ? "underline" : "none",
              // color: isActive ? "#0077B5" : "white",
            })}
          >
            <p className={`${currentLink("learn") && classes.links}`}>Learn</p>
          </NavLink>
        </div>
      </div>
      <div className={classes.navLink}>
        <div className={classes.actionMenu}>
          <a
            className={classes.clientLogin}
            href={env.portalUrl}
          >
            Client Login
          </a>
          <NavLink to={"/request-demo"}>
            <button className={classes.demoBtn}>Request a Demo</button>
          </NavLink>
        </div>
      </div>

      <div className={classes.toggleContainer}>
        <img
          className={classes.toggleIcon}
          onClick={() => setIsOpen(true)}
          src={ToggleSVG}
        />
      </div>

      <SideNavBar isOpen={isOpen} setOpen={setIsOpen} />
    </section>
  );
};

export default NavBar;
