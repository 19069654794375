import React, { useState } from "react";
import classes from "./styles/sidenav.module.css";
import LogoSVG from "../../assets/svgs/Logo.svg";
import { NavLink } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";

const SideNavBar = ({ isOpen, setOpen }) => {
  const [open, setOpenCollapse] = useState(false);

  const onServiceRoute = () => {
    if (window.location.pathname.split("/")[1] == "services") {
      return true;
    } else return false;
  };

  return (
    <div className={`${classes.container} ${isOpen ? classes.open : ""}`}>
      <div className={classes.logoSection}>
        <div className={classes.logo}>
          <NavLink className={classes.linkItem} to={"/"}>
            <img className={classes.logoContainer} src={LogoSVG} />
          </NavLink>
        </div>

        <div>
          <svg
            width="25"
            height="26"
            viewBox="0 0 28 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setOpen(false)}
          >
            <path
              d="M2.13865 2L26 24M25.8613 2L2 24"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
      <div className={classes.mainSection}>
        <div className={classes.linksContainer}>
          <NavLink
            className={classes.linkItem}
            style={({ isActive }) => ({
              textDecoration: isActive ? "underline" : "none",
              color: isActive ? "#01486D" : "black",
            })}
            to={"/"}
          >
            <p>Home</p>
          </NavLink>

          <a
            className={classes.linkItem}
            style={{
              textDecoration: onServiceRoute() ? "underline" : "none",
              color: onServiceRoute() ? "#01486D" : "black",
              // pointerEvents: "click",
            }}
          >
            <p
              // className={classes.linkItem}
              onClick={() => setOpenCollapse(!open)}
            >
              Services
            </p>
          </a>
          <Collapse in={open}>
            <div>
              <NavLink
                className={classes.subLinkItem}
                style={({ isActive }) => ({
                  textDecoration: isActive ? "underline" : "none",
                  color: isActive ? "#0077B5" : "black",
                })}
                to={"/services/"}
              >
                <p>All</p>
              </NavLink>
              <NavLink
                className={classes.subLinkItem}
                style={({ isActive }) => ({
                  textDecoration: isActive ? "underline" : "none",
                  color: isActive ? "#0077B5" : "black",
                })}
                to={"/services/liquidity"}
              >
                <p>Liquidity Solutions</p>
              </NavLink>
              <NavLink
                className={classes.subLinkItem}
                style={({ isActive }) => ({
                  textDecoration: isActive ? "underline" : "none",
                  color: isActive ? "#0077B5" : "black",
                })}
                to={"/services/market-making"}
              >
                <p>Market Making</p>
              </NavLink>
              <NavLink
                className={classes.subLinkItem}
                style={({ isActive }) => ({
                  textDecoration: isActive ? "underline" : "none",
                  color: isActive ? "#0077B5" : "black",
                })}
                to={"/services/additional-services"}
              >
                <p>Addtional Services</p>
              </NavLink>
            </div>
          </Collapse>
          <NavLink
            className={classes.linkItem}
            style={({ isActive }) => ({
              textDecoration: isActive ? "underline" : "none",
              color: isActive ? "#01486D" : "black",
            })}
            to={"/performance"}
          >
            <p>Performance</p>
          </NavLink>

          <NavLink
            className={classes.linkItem}
            style={({ isActive }) => ({
              textDecoration: isActive ? "underline" : "none",
              color: isActive ? "#01486D" : "black",
            })}
            to={"/about"}
          >
            <p>About Us</p>
          </NavLink>

          <NavLink
            className={classes.linkItem}
            style={({ isActive }) => ({
              textDecoration: isActive ? "underline" : "none",
              color: isActive ? "#01486D" : "black",
            })}
            to={"/partners"}
          >
            <p>Partners</p>
          </NavLink>

          <NavLink
            className={classes.linkItem}
            style={({ isActive }) => ({
              textDecoration: isActive ? "underline" : "none",
              color: isActive ? "#01486D" : "black",
            })}
            to={"/news"}
          >
            <p>News</p>
          </NavLink>

          <NavLink
            className={classes.linkItem}
            style={({ isActive }) => ({
              textDecoration: isActive ? "underline" : "none",
              color: isActive ? "#01486D" : "black",
            })}
            to={"/learn"}
          >
            <p>Learn</p>
          </NavLink>
        </div>
      </div>
      <div className={classes.subLinks}>
        <NavLink
          className={classes.linkItem}
          style={{ textDecoration: "none", color: "#01486D" }}
          to={"/"}
        >
          <p className={classes.linkItem}>Client Login</p>
        </NavLink>

        <NavLink to={"/request-demo"}>
          <button className={classes.demoBtn}>Request a Demo</button>
        </NavLink>
      </div>
    </div>
  );
};

export default SideNavBar;
