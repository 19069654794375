import React from "react";
import classes from "./returns.module.css";

const Returns = ({active, setActive}) => {
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <h3 className={`${classes.title} ${classes.lg}`}>
          Returns in different amounts {active === 0 && "since inception"}
        </h3>
        <h3 className={`${classes.title} ${classes.sm}`}>Returns on</h3>

        <div className={classes.chartHeaders}>
          <div className={`${classes.chartHeaderItem}  ${classes.first}`}>
            <div className={`${classes.statCircle}`}></div>
            <p>Prime Bridge's Liquidity</p>
          </div>

          <div className={`${classes.chartHeaderItem}  ${classes.second}`}>
            <div className={`${classes.statCircle} `}></div>
            <p>SP500 Index</p>
          </div>
        </div>
      </div>

      {/* {active === 0 && <div className={classes.imgContainer}>
        <h2>2022 RETURN</h2>
        </div>} */}

      {active === 0 && <div className={classes.imageContainer}>
        <img
          className={`${classes.sm} ${classes.image}`}
          src="/images/return-total-sm.svg"
          alt=""
        />
        <img
          className={`${classes.lg} ${classes.image}`}
          src="/images/return-total.svg"
          alt=""
        />
      </div>}

      {active === 1 && <div className={classes.imageContainer}>
        <img
          className={`${classes.sm} ${classes.image}`}
          src="/images/return-sm-2024.svg"
          alt=""
        />
        <img
          className={`${classes.lg} ${classes.image}`}
          src="/images/total-return-growth-lg.svg"
          alt=""
        />
      </div>}

      {active === 2 && <div className={classes.imageContainer}>
        <img
          className={`${classes.sm} ${classes.image}`}
          src="/images/return-sm-2023.svg"
          alt=""
        />
        <img
          className={`${classes.lg} ${classes.image}`}
          src="/images/total-return-growth-lg-2023.svg"
          alt=""
        />
      </div>}
    </div>
  );
};

export default Returns;
