import React, { useEffect, useRef } from "react";
import classes from "./hero.module.css";
import useIsMobile from "../../hooks/useIsMobile";

const Hero = () => {
  const videoRef = useRef();

  //   const { isMobile } = useIsMobile();

  useEffect(() => {
    videoRef?.current?.play();
  }, []);
  return (
    <div className={classes.hero}>
      <video
        webkit-playsinline={true}
        playsInline
        ref={videoRef}
        muted
        loop
        className={classes.myVideo}
        width={"1000"}
        height={"1000"}
      >
        <source
          src="https://s3.amazonaws.com/cdn.traders-central/prime-bridge-portal/forex.mp4"
          type="video/mp4"
        />
      </video>

      <div className={classes.contentSection}>
        <div>
          <h3 className={classes.title}>Prime Bridge Liquidity</h3>
          <p className={classes.subtitle}>
            See our clients returns vs traditional indexes.
          </p>
        </div>

        <div className={classes.subtitleContainer}>
          <p className={classes.subtitle}>Updated Quarterly</p>
          <p className={classes.subtitle}>Last Update: June 30, 2024</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
